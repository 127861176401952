<template>
  <dialog ref="dialog" class="sp-dialog" :class="classModifiers" @keydown.esc="handleKeyDownEscape">
    <div ref="content" class="sp-dialog__content">
      <slot />
    </div>
  </dialog>
</template>

<script>
import { props as dialogProps } from "./props.js";
</script>

<script setup>
import { onClickOutside, onKeyStroke, useCssVar, useEventListener } from "@vueuse/core";
import { computed, nextTick, onMounted, ref, watch } from "vue";
import { toBoolean } from "../../utils/props";
const emit = defineEmits(["close", "update-open"]);

const props = defineProps({
  ...dialogProps,
  position: {
    type: String,
    default: "center",
    validator: (value) => ["center", "bottom"].includes(value),
  },
});

onKeyStroke("Escape", handleKeyDownEscape);

const dialog = ref(null);
useEventListener(dialog, "animationend", handleDialogAnimationEnd);

const content = ref(null);
onClickOutside(content, conditionalHideDialog);

const mounted = ref(false);

const persistent = ref(toBoolean(props.persistent));
const ignoreEscapeKey = ref(toBoolean(props.ignoreEscapeKey));

const open = ref(toBoolean(props.open));

watch(open, (value) => {
  handleOpenChange(value);

  emit("update-open", value);

  if (!value) {
    emit("close");
  }
});

watch(
  () => props.open,
  (value) => (open.value = toBoolean(value)),
);

watch(
  () => props.isHiddenTemporarily,
  (value) => toggleTemporaryHiddenState(toBoolean(value)),
);

function toggleTemporaryHiddenState(isHidden) {
  if (!dialog.value) {
    return;
  }

  if (isHidden) {
    closeModal();
  } else {
    showModal();
  }
}

const animationEnd = ref(true);

const classModifiers = computed(() => ({
  "--no-backdrop": toBoolean(props.noBackdrop),
  "--persistent": toBoolean(props.persistent),
  "--fullscreen": toBoolean(props.fullscreen),
  "--scrollable": toBoolean(props.scrollable),
  "--animation-end": animationEnd.value,
  [`--position-${props.position}`]: props.position,
}));

/**
 * This is a reference to the body overflow css variable
 * it is used to control the body scroll when the dialog is open
 * due a bug in the dialog element that doesn't allow to set the overflow
 * property to hidden when the dialog is open
 *
 * If dialog is open, the body will have the overflow hidden
 * otherwise, the body will have the overflow inherit
 *
 * If the bug is fixed, this can be removed
 * @see https://github.com/whatwg/html/issues/7732
 * @type {import("vue").Ref<string>}
 */

const bodyScroll = useCssVar("--sp-body-overflow");

function showModal() {
  if (!mounted.value) {
    return;
  }
  animationEnd.value = false;
  dialog.value.showModal();
}

function closeModal() {
  dialog.value.close();
}

/**
 * Fired when the dialog animation ends
 * This is used to detect when the dialog is closed
 *
 * @param {AnimationEvent} event
 */
function handleDialogAnimationEnd(event) {
  if (event.pseudoElement === "::backdrop" || !event.animationName.startsWith("close--")) {
    return;
  }
  open.value = false;
  animationEnd.value = true;
}

async function conditionalHideDialog() {
  if (!open.value || persistent.value) {
    return;
  }

  closeModal();
}

function handleKeyDownEscape(event) {
  if (persistent.value || ignoreEscapeKey.value) {
    event.preventDefault();
    event.stopPropagation();
  }
}

function handleOpenChange(isOpen) {
  if (isOpen) {
    showModal();
    bodyScroll.value = "hidden";
  } else {
    closeModal();
    bodyScroll.value = "inherit";
  }
}

onMounted(() => {
  mounted.value = true;

  if (open.value) {
    nextTick(showModal);
  }
});
</script>

<style>
:host {
  display: block;
  --sp-comp-dialog-margin: auto;
  --sp-comp-dialog-backdrop-transparency: 0.523;
  --sp-comp-dialog-width: auto;
}
</style>

<style lang="scss">
dialog {
  --ease: ease-in-out;
  --animation-time: 0.5s;
  --layer-important: 2147483647;
  --size-content-3: 60ch;

  --animation-on-open: open--fade-in var(--animation-time) var(--ease);
  --animation-on-close: close--fade-out var(--animation-time) var(--ease);

  display: block;
  z-index: var(--layer-important);
  max-inline-size: min(90vw, var(--size-content-3));

  position: fixed;
  width: v-bind(width);
  min-width: v-bind(width);
  height: v-bind(height);
  overflow: hidden;
  opacity: 1;
  animation: var(--animation-on-close) forwards;
  animation-timing-function: ease-in-out;
  border-radius: var(--sp-comp-dialog-border-radius, 0.5rem);
  padding: var(--sp-comp-dialog-padding, 0);
  margin: var(--sp-comp-dialog-margin, auto);
  border: var(--sp-comp-dialog-border, 0);
  transition: all 0.3s ease-in-out;
}

dialog.--animation-end {
  opacity: 0;
  display: none;
}

dialog.--fullscreen {
  max-inline-size: 100vw;
  max-block-size: 100vh;
}

dialog.--no-backdrop::backdrop {
  background-color: transparent;
}

dialog.--position-bottom {
  --animation-on-close: close--slide-out-down var(--animation-time) var(--ease);
  --animation-on-open: open--slide-in-up var(--animation-time) var(--ease);
  inset: auto 0 0 0;
  position: fixed;
  max-inline-size: 100vw;
}

dialog[open] {
  animation: var(--animation-on-open) forwards;
}

dialog:not([open]) {
  pointer-events: none;
}
dialog::backdrop {
  animation: backdrop-fade-out var(--animation-time) ease-out forwards;
}
dialog[open]::backdrop {
  animation: backdrop-fade-in var(--animation-time) ease-out forwards;
}
dialog.--scrollable .sp-dialog__content {
  max-height: 90vh;
  overflow-y: auto;
}

/* Animation keyframes */

@keyframes open--slide-in-up {
  0% {
    transform: translateY(100%);
    // opacity: 0;
    display: none;
  }
}

@keyframes close--slide-out-down {
  to {
    transform: translateY(100%);
    display: none;
    // opacity: 0;
  }
}

@keyframes open--fade-in {
  0% {
    opacity: 0;
    display: none;
  }
}

@keyframes close--fade-out {
  to {
    display: none;
    opacity: 0;
  }
}

@keyframes backdrop-fade-in {
  0% {
    background-color: rgb(0 0 0 / 0%);
  }

  100% {
    background-color: rgb(0 0 0 / 25%);
  }
}

@keyframes backdrop-fade-out {
  0% {
    background-color: rgb(0 0 0 / 25%);
  }

  100% {
    background-color: rgb(0 0 0 / 0%);
  }
}
</style>
