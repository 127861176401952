import { computed, ref, watch } from "vue";
import { useDistanceFilterApi } from "../api/distance-filter";

export const useDistanceFilter = (props) => {
  const api = props.filterApi ?? useDistanceFilterApi(props);

  const geolocationRetrievalSupported = computed(() => navigator.geolocation);
  const currentLocationId = "current-location";

  const loading = ref(false);
  const error = ref(null);

  const lat = ref();
  const lng = ref();

  const distances = ref([5, 10, 15, 20, 25]);
  const distanceOptions = computed(() => distances.value.map((value) => ({ value, title: `${value} miles` })));

  const selectedDistancePrependText = ref("Within");
  const selectedDistance = ref();

  const selectedDistanceText = computed(() => {
    const value = Number.parseInt(selectedDistance.value);
    const option = distanceOptions.value.find((option) => option.value === value);
    return option?.title;
  });

  const isRetrievingCurrentLocation = ref(false);

  const currentLocation = computed(() => {
    const prependIcon = "gps-locate-filled";
    const isRetrieving = isRetrievingCurrentLocation.value;

    return {
      value: currentLocationId,
      name: isRetrieving ? props.currentLocationRetrievingLabel : props.currentLocationLabel,
      props: {
        prependIcon,
        prependIconProps: {
          fillColor: "primary",
        },
        ignoreActiveState: isRetrieving,
        loading: isRetrieving,
      },
    };
  });

  const prependLocations = computed(() => {
    if (!geolocationRetrievalSupported.value) {
      return [];
    }
    const options = { group: " " };
    return decorateModels(options, currentLocation.value);
  });

  const commonPlaces = ref([]);
  const googlePlaces = ref([]);

  const selectedLocation = ref(undefined);

  /**
   * If the selected location changes, clear the geo coordinates if the location is different from the current location.
   * This is to ensure that the geo coordinates are cleared when the user selects a different location.
   */
  watch(selectedLocation, (location) => {
    if (location?.value !== currentLocationId) {
      clearGeoCoordinates();
    }
  });

  const formData = computed(() => {
    const formData = new FormData();

    if (selectedDistance.value) {
      formData.set("distance", selectedDistance.value);
    }

    if (selectedLocation.value) {
      const { value } = selectedLocation.value;
      formData.set("zip", value);
    }

    if (lat.value) {
      formData.set("lat", lat.value);
    }

    if (lng.value) {
      formData.set("lng", lng.value);
    }

    return formData;
  });

  /**
   * Returns the locations to display in the dropdown.
   *
   * If there are google locations, they are returned grouped by "Search Results".
   * Otherwise, the common locations are returned grouped by "Common Locations".
   */
  const locations = computed(() => [
    ...prependLocations.value,
    ...(googlePlaces.value.length > 0 ? googlePlaces.value : commonPlaces.value),
  ]);

  /**
   * Returns the display text for the selected location.
   * This is used to display the selected location in the activator.
   *
   * The display text is constructed as follows:
   * - If there is no selected location, return undefined.
   * - If there is a selected distance, prepend the selected distance text.
   * - Append the selected location name.
   * - Join the segments with " of ".
   *
   * @returns {string | undefined}
   *
   * @example
   * // Returns "Within 5 miles of New York"
   * selectedDistance.value = 5;
   * selectedLocation.value = { name: "New York" };
   *
   * // Returns "New York"
   * selectedDistance.value = undefined;
   * selectedLocation.value = { name: "New York" };
   */
  const displayText = computed(() => {
    if (!selectedLocation.value) {
      return undefined;
    }

    const segments = [];

    if (selectedDistanceText.value) {
      segments.push(`${selectedDistancePrependText.value} ${selectedDistanceText.value}`);
    }

    const { name, value } = selectedLocation.value;
    const text = value === currentLocationId ? props.currentLocationDisplayText : name;
    segments.push(text);

    return segments.filter(Boolean).join(" of ");
  });

  async function loadCommonPlaces() {
    loading.value = true;

    try {
      const places = await api.getCommonPlaces();
      commonPlaces.value = decoratePlaceModels("Common Locations", ...places);
    } catch (e) {
      error.value = e;
    } finally {
      loading.value = false;
    }
  }

  function decoratePlaceModels(group, ...models) {
    return decorateModels({ group, props: { prependIcon: "map-pin-filled" } }, ...models);
  }

  function decorateModels(options, ...models) {
    const { group, props = {} } = options;
    return models.map((model) => ({ ...model, group, props: { ...model.props, ...props } }));
  }

  function findLocationByValue(value) {
    return locations.value.find((location) => location.value === value);
  }

  function updateSelectedLocation(value) {
    if (value === currentLocationId && !(lat.value && lng.value)) {
      retrieveCurrentLocation();
    } else {
      const location = findLocationByValue(value);
      selectedLocation.value = location;
    }
  }

  function unsetSelectedLocation() {
    selectedLocation.value = undefined;
  }

  function retrieveCurrentLocation() {
    isRetrievingCurrentLocation.value = true;

    const cleanup = () => {
      isRetrievingCurrentLocation.value = false;
    };

    navigator.geolocation.getCurrentPosition(
      ({ coords }) => {
        cleanup();
        updateGeoCoordinates(coords);
        updateSelectedLocation(currentLocationId);
      },
      () => {
        cleanup();
        unsetSelectedLocation();
        showCurrentLocationRetrievalError();
      },
    );
  }

  function showCurrentLocationRetrievalError() {
    // eslint-disable-next-line no-alert
    window.alert(props.currentLocationRetrievalErrorText);
  }

  function updateGeoCoordinates({ latitude, longitude }) {
    lat.value = latitude;
    lng.value = longitude;
  }

  function clearGeoCoordinates() {
    lat.value = undefined;
    lng.value = undefined;
  }

  /**
   * Updates the google result.
   *
   * If the result is an array, it updates the google locations.
   * In any other case, it clears the google results before proceeding.
   *
   * @param {Array<Object>|undefined} result
   */
  function updateGoogleResult(result) {
    googlePlaces.value = [];

    if (Array.isArray(result)) {
      googlePlaces.value = decoratePlaceModels("Search Results", ...result);
    }
  }

  function clear() {
    selectedLocation.value = undefined;
    selectedDistance.value = undefined;
    googlePlaces.value = [];

    clearGeoCoordinates();
  }

  return {
    // state
    loading,
    error,
    locations,
    selectedLocation,
    distanceOptions,
    selectedDistance,
    selectedDistancePrependText,
    displayText,
    formData,

    // actions
    loadCommonPlaces,
    findLocationByValue,
    updateGoogleResult,
    updateSelectedLocation,
    updateGeoCoordinates,
    clear,
  };
};
