<template>
  <div ref="root" class="sp-omnisearch">
    <input
      ref="searchField"
      v-model="searchString"
      type="text"
      :placeholder="placeholder"
      :disabled="props.disabled"
      :readonly="props.readonly"
      part="input"
      autocomplete="off"
      @change="handleSearchChange"
    />
    <slot ref="filterInputs" name="filters"></slot>
  </div>
</template>

<script setup>
/**
 * Omnisearch is an web component designed to display multi-faceted search suggestions, and direct links to content. It
 * Integrates with forms and can submit multiple form parameters if the selected option requires it. It also provides
 * callbacks to respond to user selection if form integration is not needed.
 *
 * @displayName OmniSearch
 * @group Custom Elements
 * @component sp-omnisearch
 */

import { onMounted, ref, watch } from "vue";
import { useForm } from "../../composables/form";

const root = ref(null);
const searchField = ref(null);
const filterInputs = ref(null);
const autocomplete = ref(null);
const data = ref(null);
const selectedItem = ref(null);
const searchString = ref(null);
const formValue = ref(null);
const { setValue } = useForm(root);

const props = defineProps({
  /**
   * Url to fetch autocomplete suggestions from.
   *
   * @type {String}
   * @required true
   */
  src: {
    type: String,
    required: true,
  },
  /**
   * Placeholder text to show in the search input.
   *
   * @type {String}
   * @default undefined
   */
  placeholder: {
    type: String,
    default: undefined,
  },
  /**
   * Callback triggered when a suggestion is selected. The string is executed as a Function body, and can access the
   * selected item as `item`. The function is executed in the context of the omnisearch element.
   *
   * @type {String}
   * @default undefined
   */
  onselect: {
    // FIXME: Naming this 'onSelect' caused it to appear on the root div, so we use lowercase for now.
    type: String,
    default: undefined,
  },
  /**
   * Text input used to filter items.
   *
   * @type {String}
   * @default undefined
   */
  search: {
    type: String,
    default: undefined,
  },
  /**
   * Disable input used to filter items. When the omnisearch is disabled, no form values will be submitted.
   *
   * @type {Boolean, String}
   * @default false
   */
  disabled: {
    type: [Boolean, String],
    default: false,
  },
  /**
   * Make the input used to filter items readonly.
   *
   * @type {Boolean, String}
   * @default false
   */
  readonly: {
    type: [Boolean, String],
    default: false,
  },
  /**
   * Url to fetch the autocomplete script from. This is expected to be set from an external script, not an attribute.
   *
   * @type {String}
   * @require true
   * @external true
   */
  autocompleteJsUrl: {
    type: Object, // Expected to be a computed Ref because we want to avoid a race condition where we set the value externally before that value has been loaded.
    required: true,
    external: true,
  },
  /**
   * Url to fetch the autocomplete CSS from. This is expected to be set from an external stylesheet, not an attribute.
   *
   * @type {String}
   * @require true
   * @external true
   */
  autocompleteCSSUrl: {
    type: Object, // Expected to be a computed Ref because we want to avoid a race condition where we set the value externally before that value has been loaded.
    required: true,
    external: true,
  },
});

// BEHAVIOUR

// Load the autocomplete
onMounted(async () => {
  refreshData();
  loadAutocomplete();
});

// When the src attribute is changed, get new data
watch(() => props.src, refreshData);

// When the data changes and the autocomplete is ready, update the autocomplete data
watch(() => [data.value, autocomplete.value], assignData);

// Update the search value when the search property changes
watch(
  () => props.search,
  () => (searchString.value = props.search),
);

watch(selectedItem, () => {
  // Clear the form value for the omnisearch element since we don't want to select an item and have a query
  formValue.value = null; // Set the form value for the omnisearch element

  slotSelectedItemFilters();

  // Update the search input with the select item's text.
  searchString.value = selectedItem.value.text;

  // Emit change event. This event differs from select in that it is only fired if the selected item changes.
  // Only emit change here if there is a selected item, else we'll handle it with the search change handler.
  if (selectedItem.value) {
    emitBubblingEvent("change", cloneItem(selectedItem.value));
  }
});

watch(formValue, () => {
  setValue(formValue.value);

  // Only emit change here if there is not a selected item, else we'll handle it with the selectedItem change handler.
  if (!selectedItem.value) {
    emitBubblingEvent("change", formValue.value);
  }
});

// Enable/Disable the hidden filter inputs
watch(() => props.disabled, slotSelectedItemFilters);

// INITIALIZE

// Initialize the search string from the element attribute
searchString.value = props.search;

// EVENT HANDLERS

function handleSearchChange() {
  clearSlottedFilters();
  formValue.value = searchString.value; // Set the form value for the omnisearch element
}

function handleAutocompleteSelect(item) {
  // Clone the item so the original cannot be modified unintentionally
  item = cloneItem(item);

  const handlerReturnValue = runOnAutocompleteSelect(item);

  // If the handler returns false, cancel the event
  if (handlerReturnValue === false) {
    return; // Cancel the selection
  }

  // Emit the event before the possibility that the handler replaces it
  emitBubblingEvent("select", item);

  if (handlerReturnValue !== undefined) {
    item = handlerReturnValue; // Replace the item with the new object
  }

  if (item.url) {
    navigateToUrlUsingAnchorTag(item.url);
  } else {
    selectedItem.value = item;
  }
}

// HELPER FUNCTIONS

function runOnAutocompleteSelect(item) {
  const handlerCode = props.onselect;
  if (handlerCode) {
    // Prefer Function constructor over eval for security reasons
    // eslint-disable-next-line no-new-func
    const handler = new Function("item", handlerCode);
    return handler.call(getHostElement(), item);
  }
}

// Create hidden form inputs in the light DOM for the selected item's filters
function slotSelectedItemFilters() {
  const newFilters = selectedItem.value?.filters;
  if (newFilters) {
    slotFilters(newFilters);
  }
}

// Create hidden form inputs in the light DOM for the given filters
function slotFilters(filters) {
  clearSlottedFilters();

  const hostElement = getHostElement();

  for (const [name, value] of Object.entries(filters)) {
    const input = document.createElement("input");
    input.setAttribute("slot", "filters");
    input.setAttribute("type", "hidden");
    input.setAttribute("name", name);
    input.setAttribute("value", value);
    if (props.disabled) {
      input.setAttribute("disabled", "");
    }
    hostElement.append(input); // Append a new hidden input for the slot
  }
}

// Clear the slotted inputs
function clearSlottedFilters() {
  filterInputs.value.assignedElements().forEach((element) => element.remove());
}

// Uses an anchor tag to simulate a link click in the Light DOM. This allows event handlers to intercept the click if
// desired.
function navigateToUrlUsingAnchorTag(url) {
  const a = document.createElement("a");
  a.href = url;
  getHostElement().appendChild(a);
  a.click();
  a.remove();
}

// Create and dispatch a native event that bubbles
function emitBubblingEvent(eventType, payload) {
  const event = new CustomEvent(eventType, {
    detail: payload,
    bubbles: true,
  });

  getHostElement().dispatchEvent(event);
}

function getHostElement() {
  return root.value.parentNode.host;
}

function assignData() {
  if (autocomplete.value && data.value) {
    autocomplete.value.setData(data.value);
  }
}

// Clone the item so the original cannot be modified unintentionally
function cloneItem(item) {
  return Object.assign({}, item);
}

async function refreshData() {
  if (props.src) {
    const response = await fetch(props.src);
    data.value = await response.json();
  } else {
    data.value = [];
  }
}

async function loadAutocomplete() {
  const jsFetch = window.Sparkle.Codeload.importIf(
    !window.DoctorFinder?.Views?.Autocomplete,
    props.autocompleteJsUrl.value,
  );

  const cssFetch = window.Sparkle.Codeload.custom({ cssContainer: root.value }).import(props.autocompleteCSSUrl.value);

  await Promise.all([jsFetch, cssFetch]);

  autocomplete.value = new window.DoctorFinder.Views.Autocomplete(searchField.value, [], {
    onSelect: handleAutocompleteSelect,
  });
  autocomplete.value.render();
}
</script>
