<template>
  <component :is="tag" class="sp-text" :class="classModifiers">
    <slot />
  </component>
</template>

<script>
const types = ["h1", "h2", "h3", "h4", "h5", "h6", "title", "subtitle", "body-1", "body-2", "label"];
</script>

<script setup>
import { computed } from "vue";

const props = defineProps({
  tag: {
    type: String,
    default: "div",
  },
  type: {
    type: String,
    default: "body-1",
    validator: (value) => types.includes(value),
  },
  color: {
    type: String,
    default: undefined,
  },
});

const type = computed(() => (props.type ?? types.includes(props.tag) ? props.tag : undefined));

const classModifiers = computed(() => ({
  [`--${type.value}`]: type.value,
}));
</script>

<style>
:host {
  display: block;
  margin: 0;
  box-sizing: border-box;
  --text-color: var(--sp-comp-text-color, var(--sp-sys-color-on-surface, #000));
}
:host([color]) {
  --text-color: v-bind(color);
}
</style>

<style lang="scss" scoped>
.sp-text,
.--body-1 {
  color: var(--text-color);
  font-size: var(--sp-comp-text-body-1-font-size, 1rem);
  font-weight: var(--sp-comp-text-body-1-font-weight, normal);
  font-family: var(--sp-comp-text-body-1-font-family, inherit);
  line-height: var(--sp-comp-text-body-1-font-line-height, 1.3);
  margin: 0;
  padding: 0;
}

@each $type in h1, h2, h3, h4, h5, h6, "title", "subtitle", body-2, link, label {
  .--#{$type} {
    font-size: var(--sp-comp-text-#{$type}-font-size, 1rem);
    font-weight: var(--sp-comp-text-#{$type}-font-weight, normal);
    font-family: var(--sp-comp-text-#{$type}-font-family, inherit);
    line-height: var(--sp-comp-text-#{$type}-line-height, 1.3);
    margin: 0;
  }
}
</style>
