export const props = {
  /**
   * Can be an array of objects or strings.
   *
   * By default objects should have title and value properties.
   * Keys to use for these can be changed with the item-title, item-value, and item-props props.
   * If strings are used, they will be used for both title and value.
   *
   * @type {Array<String|Object>}
   * @default []
   */
  items: {
    type: Array,
    default: () => [],
  },
  /**
   * Property on supplied items that contains its title.
   *
   * @type {String}
   * @default "title"
   */
  itemTitle: {
    type: String,
    default: "title",
  },
  /**
   * Property on supplied items that contains its subtitle.
   *
   * @type {String}
   * @default undefined
   */
  itemSubtitle: {
    type: String,
    default: "subtitle",
  },
  /**
   * Property on supplied items that contains its value.
   *
   * @type {String}
   * @default "value"
   */
  itemValue: {
    type: String,
    default: "value",
  },
  /**
   * Props object that will be applied to each item component.
   * A `true` value will treat the original object as raw props and pass it directly to the component.
   *
   * @type {String|Boolean}
   * @default "props"
   */
  itemProps: {
    type: [String, Boolean],
    default: "props",
  },
  /**
   * An array containing the values of currently selected items.
   *
   * @type {Array}
   * @default undefined
   */
  selected: {
    type: Array,
    default: undefined,
  },
  /**
   * Icon to prepend to the selected item.
   * This will be rendered before the selected item's title.
   *
   * @type {String}
   * @default undefined
   */
  selectedPrependIcon: {
    type: String,
    default: undefined,
  },
  selectedPrependIconFillColor: {
    type: String,
    default: undefined,
  },
  /**
   * Changes the selection behavior to allow multiple selections.
   *
   * @type {Boolean}
   * @default false
   */
  multiple: {
    type: [Boolean, String],
    default: false,
  },
  /**
   * Renders the list with the provided subheader on top.
   *
   * @type {String}
   * @default undefined
   */
  subheader: {
    type: String,
    default: undefined,
  },
  /**
   * The strategy to listen for key events.
   * Per default, the list only listens for key events when it is focused.
   * If the list is part of a host component that manages focus, you can set this prop to `true`.
   *
   * - `focus`: Only listen for key events when the list is focused.
   * - `true`: Listen for key events all the time.
   * - `false`: Never listen for key events.
   *
   * @default "focus"
   * @type {Boolean|String}
   */
  listenOnKeyEvent: {
    type: [Boolean, String],
    default: "focus",
  },
  /**
   * If true, the list item will not be selectable.
   * This is useful when the list is used as a menu.
   *
   * @default false
   */
  readonly: {
    type: [Boolean, String],
    default: false,
  },
};
