<template>
  <sp-row>
    <PromoCodeRowSummary v-if="promoCode" />
    <PromoCodeRowInput v-else-if="paymentRequired" />
  </sp-row>
</template>

<script setup>
import { computed } from "vue";
import { usePurchaseStore } from "../store/purchase";
import PromoCodeRowInput from "./PromoCodeRowInput.ce.vue";
import PromoCodeRowSummary from "./PromoCodeRowSummary.ce.vue";

const purchaseStore = usePurchaseStore();
const promoCode = computed(() => purchaseStore.promoCode.value);
const paymentRequired = computed(() => purchaseStore.paymentRequired.value);
</script>
