<template>
  <div class="sp-card-title" :class="classModifiers">
    <div class="sp-card-title__content">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
const props = defineProps({
  oneline: {
    type: [Boolean, String],
    default: false,
  },
});

const classModifiers = computed(() => ({
  "--oneline": props.oneline,
}));
</script>

<style>
:host {
  display: block;
}
::slotted(*) {
  margin: 0;
  padding: 0;
}
</style>
<style scoped lang="scss">
.sp-card-title {
  position: relative;
  width: 100%;

  &.--oneline {
    max-width: var(--sp-comp-card-title-max-width, 40ch);

    .sp-card-title__content {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
