<template>
  <sp-text :type="tag" :tag="tag" class="sp-heading">
    <slot />
  </sp-text>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  level: {
    type: Number,
    validiator: (value) => value >= 1 && value <= 6,
    default: 1,
  },
});

const tag = computed(() => `h${props.level}`);
</script>

<style>
:host {
  display: block;
}
</style>
