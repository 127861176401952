<template>
  <BasePanel>
    <slot name="leading-section" :resource="resource" />

    <slot name="form-section" :form-data="formData">
      <ReviewSection title="Data" icon="data">
        <sp-col>{{ formData }}</sp-col>
      </ReviewSection>
    </slot>

    <slot v-if="paymentEnabled" name="payment-section">
      <ReviewSectionPayment />
    </slot>
  </BasePanel>
</template>

<script setup>
import { computed } from "vue";
import { usePurchaseStore } from "../store/purchase";
import BasePanel from "./BasePanel.ce.vue";
import ReviewSection from "./ReviewSection.ce.vue";
import ReviewSectionPayment from "./ReviewSectionPayment.ce.vue";

const purchaseStore = usePurchaseStore();

defineEmits(["goto"]);

defineProps({
  resource: {
    type: Object,
    default: undefined,
  },
});

const formData = computed(() => purchaseStore.entries.value ?? {});
const paymentEnabled = computed(() => purchaseStore.paymentEnabled.value);
</script>
