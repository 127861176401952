import { watchImmediate } from "@vueuse/core";
import { isRef, ref, watch } from "vue";
import { useHost } from "./host";

export function useForm(root) {
  const { host } = useHost(root);

  const internals = ref(null);
  const form = ref(null);

  watch(host, (host) => {
    if (!host || internals.value) {
      return;
    }

    try {
      internals.value = host.attachInternals();
    } catch (error) {
      // noop
    }
  });

  watch(internals, (internals) => {
    form.value = internals?.form;
  });

  let watchFormValueStop;

  function setValue(value) {
    watchFormValueStop?.();

    if (isRef(value)) {
      watchFormValueStop = watchImmediate(value, (value) => {
        internals.value?.setFormValue(value);
      });
    } else {
      internals.value.setFormValue(value);
    }
  }

  return {
    internals,
    form,

    /**
     * Sets the form value.
     * The value can be a ref or a value.
     * If the value is a ref, the form value will be updated when the ref value changes.
     * Otherwise, the form value will be updated immediately.
     *
     * @param {import('vue').Ref | any} value - The form element value.
     */
    setValue,
  };
}
