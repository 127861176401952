import { v4 as uuid } from "uuid";
import { onMounted, ref } from "vue";

const stripeSDKUrl = "https://js.stripe.com/v3/";

/**
 * Stripe payment composable provides methods to interact with the Stripe SDK.
 *
 * @param {Object} config
 * @param {String} config.publishableKey - Stripe api key
 *
 * @throws {Error} - If publishableKey is not provided
 */
export function useStripe(host, config) {
  const { publishableKey } = config;

  const shadowHosts = ref([]);
  const mountId = ref();
  const mountPoint = ref();
  const slotName = ref(config.slotName ?? `mount-${uuid()}`);
  const elements = ref();
  const paymentType = ref();

  if (!publishableKey) {
    throw new Error("Stripe publishable key is required");
  }

  /**
   * Stripe instance
   * @type {import("vue").Ref<Stripe | null>}
   */
  const instance = ref(null);

  /**
   * Connect to the Stripe SDK
   *
   * We need to load the Stripe SDK before we can use it.
   * It is mandatory to fetch the SDK from the Stripe CDN in order to comply with PCI regulations.
   *
   * @returns {Promise<void>}
   */
  async function connect() {
    if (instance.value) {
      return;
    }

    if (window.Stripe === undefined) {
      await import(/* @vite-ignore */ stripeSDKUrl);
    }

    instance.value = new window.Stripe(publishableKey);
  }

  async function createElement(type, options, elementOptions) {
    if (!instance.value) {
      await connect();
    }

    if (!elements.value) {
      initializeElements(options);
    }

    const element = elements.value.create(type, elementOptions);
    element.on("change", (event) => (paymentType.value = event.value.type));

    return element;
  }

  function initializeElements(options) {
    elements.value = instance.value.elements(options);
  }

  async function mountElement(element) {
    if (!mountPoint.value) {
      await initMountPoint();
    }
    element.mount(mountPoint.value);
  }

  async function createAndMountElement(type, options, elementOptions) {
    const element = await createElement(type, options, elementOptions);
    await mountElement(element);
    return element;
  }

  function createMountId() {
    const prefix = config?.mountPrefix ?? host.value.localName;
    return `${prefix}-mount-point-${uuid()}`;
  }

  function createSlot(slotName) {
    const node = document.createElement("slot");
    node.slot = slotName;
    node.name = slotName;
    return node;
  }

  function createMountPoint() {
    const node = document.createElement("div");
    node.id = mountId.value;
    node.classList.add(`${host.value.tagName.toLowerCase(0)}-mount-point`);
    return node;
  }

  /**
   * Initialize the mount point
   *
   * We traverse the shadow DOM tree to locate the root host element and establish a mount point.
   * This mount point is a div element intended to fill the slot defined within the custom element.
   * Since the mount point is created in the light DOM, it will be projected into the shadow DOM.
   */
  async function initMountPoint() {
    shadowHosts.value = [];
    let parentHost = host.value;

    // Traverse the shadow DOM tree to locate the root host element
    do {
      shadowHosts.value.push(parentHost);
      parentHost = parentHost.getRootNode().host;
    } while (parentHost !== undefined);

    const hosts = [...shadowHosts.value];
    const root = hosts.pop();
    if (!root.querySelector(`[slot="${slotName.value}"]`)) {
      const div = document.createElement("div");
      div.slot = slotName.value;
      root.appendChild(div);
    }

    // Create a mount point within the root host element
    const container = root.querySelector(`[slot="${slotName.value}"]`);
    container.appendChild(createMountPoint());

    // Create a slot within each shadow host element
    hosts.forEach((host) => {
      host.appendChild(createSlot(slotName.value));
    });

    mountPoint.value = document.getElementById(mountId.value);
  }

  async function confirmPayment(confirmParams) {
    const { error: validationError } = await validatePayment();

    if (validationError) {
      return { error: validationError };
    }

    const { error } = await instance.value.confirmPayment({
      elements: elements.value,
      confirmParams,
    });

    return {
      error,
    };
  }

  async function validatePayment() {
    return elements.value.submit();
  }

  onMounted(async () => {
    mountId.value = createMountId();
  });

  return {
    // State
    instance,
    slotName,
    paymentType,

    // Methods
    createElement,
    mountElement,
    createAndMountElement,
    validatePayment,
    confirmPayment,
  };
}
