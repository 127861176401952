import { BaseApi } from "../../../../shared/api/base";
import { Result } from "../models/result";

export class PurchaseApi extends BaseApi {
  constructor() {
    super({ model: Result });
  }
}

export function usePurchaseApi() {
  const api = new PurchaseApi();

  return {
    addToCart: async (url) => api.post(url),
    extendExpiration: async (url) => api.patch(url),
    submitForm: async (url, data) => api.post(url, data),
    updateForm: async (url, data) => api.put(url, data),
    applyPromoCode: async (url, promoCode) => api.patch(url, { promo_code: promoCode }),
    removePromoCode: async (url) => api.patch(url),
  };
}
