<template>
  <div>
    <sp-label v-if="label" for="select" block>{{ label }}</sp-label>
    <sp-quantum-select
      id="select"
      ref="container"
      class="sp-select"
      hide-search-bar
      hide-title-bar
      close-on-select
      :class="modifierClass"
      :open="menuOpen"
      :items="items"
      :item-title="itemTitle"
      :item-value="itemValue"
      :item-subtitle="itemSubtitle"
      :selected.prop="model"
      :multiple="multiple"
      @update-open="handleMenuUpdateOpen"
      @update-selected="handleUpdateSelected"
      @keydown.down="handleMenuKeyDown"
    >
      <div slot="activator" class="sp-select-activator">
        <slot name="activator">
          <sp-text-field
            class="sp-select-activator__input"
            :inputmode="inputmode"
            :readonly="inputmode === 'none'"
            :value="textValue"
            :disabled="disabled"
            :placeholder="placeholder"
            :prepend-icon="prependIcon"
            :append-icon="appendIcon"
            :type="type"
            :loading="loading"
            @click.prevent.stop="menuOpen = !menuOpen"
            @input="handleTextInput"
          >
            <div slot="prepend">
              <slot name="prepend" @click="handlePrependClick"></slot>
            </div>
            <div slot="append">
              <slot name="append" @click="handleAppendClick">
                <sp-caret v-if="caretVisible" :active="menuOpen" />
              </slot>
            </div>
          </sp-text-field>
        </slot>
      </div>
    </sp-quantum-select>
  </div>
</template>

<script setup>
import { onClickOutside, watchImmediate } from "@vueuse/core";
import { computed, nextTick, ref, watch } from "vue";
import { toArray, toBoolean } from "../../utils/props";
import { isObjectLike } from "../../utils/types";

const emit = defineEmits(["input", "text-input", "click-append", "click-prepend", "update-menu"]);

const props = defineProps({
  /**
   * Sets the input’s placeholder text.
   *
   * @type {String}
   * @default undefined
   */
  placeholder: {
    type: String,
    default: undefined,
  },
  /**
   * Can be an array of objects or strings.
   *
   * By default objects should have title and value properties.
   * Keys to use for these can be changed with the item-title, item-value, and item-props props.
   * If strings are used, they will be used for both title and value.
   *
   * @type {Array<String|Object>}
   * @default []
   */
  items: {
    type: Array,
    default: () => [],
  },
  /**
   * Property on supplied items that contains its title.
   *
   * @type {String}
   * @default "title"
   */
  itemTitle: {
    type: String,
    default: "title",
  },
  /**
   * Property on supplied items that contains its subtitle.
   *
   * @type {String}
   * @default undefined
   */
  itemSubtitle: {
    type: String,
    default: undefined,
  },
  /**
   * Property on supplied items that contains its value.
   *
   * @type {String}
   * @default "value"
   */
  itemValue: {
    type: String,
    default: "value",
  },
  /**
   * Creates a sp-icon component before default content in the prepend slot.
   *
   * @type {String}
   * @default undefined
   */
  prependIcon: {
    type: String,
    default: undefined,
  },
  /**
   * Creates a sp-icon component after default content in the append slot.
   *
   * @type {String}
   * @default undefined
   */
  appendIcon: {
    type: String,
    default: undefined,
  },
  /**
   * Removes the ability to click or target the input.
   *
   * @type {Boolean}
   * @default false
   */
  disabled: {
    type: Boolean,
    default: false,
  },
  /**
   * Removes the ability to edit the input’s value.
   *
   * @type {String}
   * @default "none"
   */
  inputmode: {
    type: String,
    default: "none",
    validator: (value) => {
      const validValues = ["none", "text", "decimal", "numeric", "tel", "search", "email", "url"];
      return validValues.includes(value);
    },
  },
  /**
   * The model value of the component.
   * If the component supports the multiple prop, this defaults to an empty array.
   *
   * @type {String|Array|Object|Number}
   * @default undefined
   */
  value: {
    type: [String, Array, Object, Number],
    default: undefined,
  },
  /**
   * Changes select to multiple.
   * Accepts array for value.
   *
   * @type {Boolean}
   * @default false
   */
  multiple: {
    type: Boolean,
    default: false,
  },
  /**
   * Shows a loading indicator.
   *
   * @type {Boolean}
   * @default false
   */
  loading: {
    type: Boolean,
    default: false,
  },
  /**
   * Changes the selection behavior to return the object directly rather than the value specified with item-value.
   *
   * @type {Boolean}
   * @default false
   */
  returnObject: {
    type: Boolean,
    default: false,
  },
  /**
   * Sets the input’s type attribute.
   *
   * @type {String}
   * @default "text"
   */
  type: {
    type: String,
    default: "text",
  },
  /**
   * Hides the no data slot.
   *
   * @type {Boolean}
   * @default false
   */
  hideNoData: {
    type: Boolean,
    default: false,
  },
  /**
   * Text input used to filter items.
   *
   * @type {String}
   * @default undefined
   */
  noDataText: {
    type: String,
    default: "No data",
  },
  menu: {
    type: Object,
    default: () => ({}),
  },
  /**
   * Prepends the selected value with the specified string.
   * This is useful for adding a prefix to the selected value.
   *
   * @type {String}
   * @default undefined
   */
  prependSelectedWith: {
    type: String,
    default: undefined,
  },
  prompt: {
    type: String,
    default: undefined,
  },
  label: {
    type: String,
    default: undefined,
  },
});

const container = ref(null);

const loading = computed(() => toBoolean(props.loading));
const returnObject = computed(() => toBoolean(props.returnObject));
const multiple = computed(() => toBoolean(props.multiple));
const disabled = computed(() => toBoolean(props.disabled));

const items = computed(() => {
  const items = [];

  if (props.prompt) {
    items.push({ [props.itemTitle]: props.prompt, [props.itemValue]: null });
  }

  return [...items, ...props.items];
});

const menuOpen = ref(toBoolean(props.menu));
watch(menuOpen, (value) => emit("update-menu", value));

function handleMenuUpdateOpen({ detail }) {
  const [value] = detail;
  menuOpen.value = value;
}

function handleMenuKeyDown() {
  if (!menuOpen.value) {
    menuOpen.value = true;
  }
}

const modifierClass = computed(() => ({
  "--disabled": props.disabled,
  "--is-active": menuOpen.value,
}));

const model = ref();
watch(model, () => {
  if (model.value.length === 0 && props.prompt) {
    model.value = [null];
  }
  const entries = returnObject.value ? model.value.map(findItem) : model.value;
  emit("input", multiple.value ? entries : entries?.[0]);
});
watchImmediate(
  () => props.value,
  (value) => (model.value = toArray(value ?? [])),
);

const textValue = computed(() => {
  if (multiple.value) {
    return model.value?.length ? `${model.value.length} selected` : "";
  }

  const item = findItem(model.value[0]);
  const title = getItemTitle(item);

  if (title) {
    const isPrompt = props.prompt && items.value[0] === item;
    const prepend = props.prependSelectedWith && !isPrompt ? props.prependSelectedWith : null;
    return [prepend, title].filter(Boolean).join(" ");
  }

  return undefined;
});

function findItem(value) {
  return items.value.find((item) => String(getItemValue(item)) === String(value));
}

const hasItems = computed(() => props.items?.length > 0);
const caretVisible = computed(() => hasItems.value);

function getItemTitle(item) {
  return getItemAttribute(item, props.itemTitle);
}

function getItemValue(item) {
  return getItemAttribute(item, props.itemValue);
}

function getItemAttribute(item, attribute) {
  return isObjectLike(item) ? item[attribute] : item;
}

function handlePrependClick() {
  emit("click-prepend");
}

function handleAppendClick() {
  emit("click-append");
}

function handleTextInput({ detail }) {
  const [value] = detail;
  emit("text-input", value);
}

function handleUpdateSelected({ detail }) {
  const [value] = detail;
  model.value = value;

  // This is required to close the menu after a selection is made and all events have been handled.
  nextTick(closeMenu);
}

onClickOutside(container, closeMenu);

function closeMenu() {
  menuOpen.value = false;
}
</script>

<style>
:host {
  display: block;
}
</style>

<style lang="scss" scoped>
.sp-select-list-container {
  position: relative;
  width: 100%;
}

sp-label {
  margin-bottom: var(--sp-ce-select-label-margin-bottom, var(--sp-ref-spacing-3));
}

[slot="append"],
[slot="prepend"] {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sp-select-list__no-result {
  padding: var(--sp-ce-select-no-result-padding, 1rem);
  color: var(--sp-ce-select-no-result-color, #aaa);
}
</style>
