<template>
  <div class="action-bar">
    <sp-button class="action-keep-original" color="secondary" @click="$emit('keep-original')">
      Keep original
    </sp-button>
    <sp-spacer></sp-spacer>

    <template v-if="hasMany">
      <sp-button
        class="action-prev-candidate"
        :disabled="!hasPrevious"
        icon="arrow-left"
        color="default"
        @click="selectPreviousCandidate"
      >
        Previous
      </sp-button>

      <div class="pagination-controls-container">
        <sp-slider-pagination-controls
          class="slider-pagination-controls"
          item-value="uuid"
          :selected="selectedCandidate"
          :items="candidates"
          @update-selected="updateSelectedCandidate"
        ></sp-slider-pagination-controls>

        <sp-button
          :disabled="!canGenerateMoreCandidates"
          class="action-generate-another --icon-btn"
          color="secondary"
          size="x-small"
          icon="add-bold"
          @click="generateContent"
        ></sp-button>
      </div>

      <sp-button
        class="action-next-candidate"
        :disabled="!hasNext"
        icon="arrow-right"
        color="default"
        @click="selectNextCandidate"
        >Next</sp-button
      >
    </template>

    <template v-else>
      <sp-button class="action-generate-another" color="secondary" @click="generateContent">
        <sp-icon slot="prepend" class="action-generate-another__icon" name="add-bold"></sp-icon>
        Generate Another
      </sp-button>
    </template>

    <sp-spacer></sp-spacer>
    <sp-button class="action-use-selected" :disabled="!canUseSelected" @click="$emit('use-selected')">
      Use Selection
    </sp-button>
  </div>
</template>

<script setup>
import { computed } from "vue";

const emit = defineEmits([
  "select-candidate",
  "keep-original",
  "generate-content",
  "use-selected",
  "select-previous",
  "select-next",
]);

const props = defineProps({
  candidates: {
    type: Array,
    default: () => [],
  },
  selectedCandidate: {
    type: Object,
    default: null,
  },
  hasMany: {
    type: Boolean,
    default: false,
  },
  hasPrevious: {
    type: Boolean,
    default: false,
  },
  hasNext: {
    type: Boolean,
    default: false,
  },
});

const maxCandidates = 9;
const canGenerateMoreCandidates = computed(() => props.candidates.length < maxCandidates);

const canUseSelected = computed(() => props.selectedCandidate?.status.isSuccess);

function selectCandidate(candidate) {
  emit("select-candidate", candidate);
}

function generateContent() {
  emit("generate-content");
}

function selectPreviousCandidate() {
  emit("select-previous");
}

function selectNextCandidate() {
  emit("select-next");
}

function updateSelectedCandidate({ detail }) {
  const [candidate] = detail;
  selectCandidate(candidate);
}
</script>

<style>
:host {
  display: block;
}
</style>

<style scoped>
.action-bar {
  display: flex;
  align-items: center;
  /* padding: 1rem; */
  width: 100%;
  padding-bottom: 1rem;
}

.action-keep-original {
  --sp-ce-button-secondary-text-color: var(--sp-ce-button-text-color-danger);
  --sp-ce-button-secondary-hover-text-color: var(--sp-sys-color-danger);
}

.action-generate-another {
  --sp-ce-button-secondary-text-color: var(--sp-ce-button-text-color-success);
  --sp-ce-button-secondary-hover-text-color: var(--sp-sys-color-success);
}

.action-generate-another.--icon-btn {
  --sp-ce-button-secondary-bg-color: transparent;
  --sp-ce-button-secondary-hover-bg-color: transparent;
}

.action-generate-another__icon {
  --size: var(--sp-icon-size-small-x);
  --fill: var(--sp-sys-color-success);
  margin-left: -0.25ch; /* icon offset */
}

.action-use-selected {
  --sp-ce-button-hover-opacity: 0.3 !important;
  --hover-bg-opacity: 0.3 !important;
}

.action-next-candidate,
.action-prev-candidate {
  --sp-ce-button-bg-color: white;
  --sp-ce-button-text-color: var(--sp-sys-color-primary);
  --sp-ce-button-size: 2.25rem !important;
  margin-inline: 0.5rem;
}

.ellipse-btn::part(icon) {
  --size: 0.5rem;
}

.pagination-controls-container {
  --height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--height);
  border-radius: calc(var(--height) / 2);
  background: #f2f2f2;
  padding-inline: 0.75rem 0.5rem;
}
</style>
