import { computed } from "vue";
import { useBreakpoints } from "./breakpoints";
import { useModifiers } from "./classes";

const { isSmScreen } = useBreakpoints();

export function useVariant(props) {
  const variant = computed(() => (isSmScreen.value && props.smVariant ? props.smVariant : props.variant));
  const modifiersProps = computed(() => ({ variant: variant.value }));
  const { modifiers } = useModifiers(modifiersProps, "variant");
  const variantModifiers = computed(() => modifiers.value);

  return {
    variantModifiers,
  };
}
