<template>
  <component :is="tag" :href="href" class="sp-breadcrumbs-item" :class="classModifiers">
    <slot />
  </component>
</template>
<script setup>
import { computed } from "vue";
const props = defineProps({
  href: {
    type: String,
    default: undefined,
  },
  disabled: {
    type: [Boolean, String],
    default: false,
  },
  tag: {
    type: String,
    default: "a",
  },
});
const classModifiers = computed(() => ({
  "--disabled": props.disabled,
}));
</script>
<style>
:host {
  display: block;
}
:host([disabled]) {
  pointer-events: none;
  color: var(--color-gray-500);
}
</style>
<style scoped lang="scss">
.sp-breadcrumbs-item {
  font-family: var(--sp-breadcrumbs-item-font-family, var(--sp-sys-font-family-medium));
  font-weight: var(--sp-breadcrumbs-item-font-weight, var(--sp-sys-font-weight-medium));
  font-size: var(--sp-breadcrumbs-item-font-size, var(--sp-ref-font-size-small));
  text-decoration: var(--sp-breadcrumbs-item-text-decoration, none);
  color: var(--sp-breadcrumbs-item-color, var(--sp-sys-color-link));

  &:hover {
    text-decoration: var(--sp-breadcrumbs-item-text-decoration-hover, underline);
  }

  &.--disabled {
    color: var(--sp-breadcrumbs-item-color-disabled, var(--sp-sys-color-on-surface));
    font-family: var(--sp-breadcrumbs-item-font-family-disabled, var(--sp-sys-font-family-normal));
    font-weight: var(--sp-breadcrumbs-item-font-weight-disabled, var(--sp-sys-font-weight-normal));
  }
}
</style>
