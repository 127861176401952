<template>
  <Transition>
    <div v-if="isVisible" class="sp-card-content-overlay">
      <slot />
    </div>
  </Transition>
</template>
<script setup>
import { computed } from "vue";
import { toBoolean } from "../../utils/props";

const props = defineProps({
  visible: {
    type: [Boolean, String],
    default: false,
  },
});

const isVisible = computed(() => toBoolean(props.visible));
</script>
<style>
:host {
  display: flex;
  flex-direction: column;
}

.sp-card-content-overlay {
  background: var(--sp-card-content-overlay-background, rgba(256, 256, 256, 0.9));
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: calc(var(--card-title-z-index) - 1);
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.7s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
