<template>
  <sp-row>
    <sp-spacer></sp-spacer>
    <sp-col cols="4" align-items="end" sm="12">
      <strong>Total {{ totalAmount }}</strong>
    </sp-col>
  </sp-row>
</template>

<script setup>
import { computed } from "vue";
import { usePurchaseStore } from "../store/purchase";

const purchaseStore = usePurchaseStore();

const totalAmount = computed(() => purchaseStore.totalAmount.value);
</script>
