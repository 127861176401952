<template>
  <BasePanel :preamble="preamble">
    <div ref="slotContainer">
      <slot />
    </div>
  </BasePanel>
</template>

<script setup>
import { useEventListener } from "@vueuse/core";
import { ref, watch } from "vue";
import { usePurchaseStore } from "../store/purchase";
import BasePanel from "./BasePanel.ce.vue";

const purchaseStore = usePurchaseStore();

const slotContainer = ref(null);

const formSlot = ref(null);
const formElement = ref(null);

defineProps({
  preamble: {
    type: String,
    default: "Add your data.",
  },
});

watch(slotContainer, (value) => {
  formSlot.value = value.querySelector("slot");
});

/**
 * In order to access the form element, we need to wait for the slot to be populated.
 * We listen for the slotchange event, extract the form element, and store it in the form element ref.
 */
useEventListener(formSlot, "slotchange", () => {
  const slotContent = formSlot.value?.assignedElements?.()?.at(0);
  formElement.value = slotContent?.querySelector("form");
  removeUnwantedFormElements();
  purchaseStore.setFormElement(formElement.value);
});

function removeUnwantedFormElements() {
  formElement.value?.querySelector("[type=submit]")?.remove();
  formElement.value?.querySelector("[data-behaviour=preview-form]")?.remove();
}

/**
 * Intercept the submit event and prevent the default behaviour.
 * This allows us to handle the form submission ourselves.
 * @param {Event} e
 */
useEventListener(formElement, "submit", (e) => {
  e.preventDefault();
  e.stopPropagation();
});
</script>
