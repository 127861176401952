export class PaymentError extends Error {
  constructor(message, details, code) {
    super(message);
    this.details = details;
    this.code = code;
  }

  static failed(error) {
    return new PaymentError("Payment failed", error.message, error.code);
  }
}
