<template>
  <sp-text-field
    readonly
    clearable
    persistent-clear
    class="activator"
    color="surface"
    :value="value"
    :placeholder="placeholder"
    @click-clear="$emit('clear')"
  >
    <sp-icon v-if="prependIcon" slot="prepend" class="default-filter__activator-icon" :name="prependIcon" />
    <sp-icon v-if="appendIcon" slot="append" class="default-filter__clear-icon" :name="prependIcon" />
  </sp-text-field>
</template>

<script setup>
import { computed } from "vue";

defineEmits(["clear"]);

const props = defineProps({
  value: {
    type: String,
    default: undefined,
  },
  label: {
    type: String,
    required: true,
  },
  prependIcon: {
    type: String,
    default: undefined,
  },
  appendIcon: {
    type: String,
    default: undefined,
  },
  closeOnSelect: {
    type: [Boolean, String],
    default: false,
  },
});

const placeholder = computed(() => props.value ?? props.label);
</script>

<style scoped lang="scss">
@import "../../../../../../assets/stylesheets/mixins/breakpoint";

.activator {
  --activator-height: 44px;
  --activator-border-color: #a4a4a4;
  --activator-border-width: 1px;
  --activator-border-radius: var(--sp-sys-form-field-border-radius);
  --activator-color: #656f75;

  --divider-display: none;
  --sp-comp-text-field-color: var(--activator-color);
  --sp-comp-text-field-padding-inline: var(--sp-ref-spacing-4);
  --sp-comp-text-field-placeholder-color: var(--activator-border-color);
  --sp-comp-text-field-background-color: white;
  --sp-comp-text-field-border-color: var(--activator-border-color);
  --sp-comp-text-field-border-width: var(--activator-border-width);
  --sp-comp-text-field-border-radius: var(--activator-border-radius);
  --sp-comp-text-field-min-height: var(--activator-height);
  --sp-comp-text-field-cursor: pointer;
  --sp-comp-text-field-content-padding-inline: var(--sp-ref-spacing-5);

  margin-bottom: 8px;

  @include sp-breakpoint(sm) {
    --button-justify-content: start;
    --activator-height: 60px;
    --activator-border-radius: 0;
    --divider-display: block;
    --sp-comp-text-field-border-width: var(--activator-border-width) 0;
    margin-bottom: 0;
  }

  &:focus,
  &:focus-visible,
  &:focus-within {
    outline: none;
    box-shadow: none;
  }

  &::after {
    --divider-height: 40px;
    content: "";
    width: 1px;
    height: var(--divider-height);
    display: var(--divider-display);
    background-color: var(--activator-border-color);
    position: absolute;
    top: 50%;
    margin-top: calc(var(--divider-height) / -2);
    right: 0;
  }

  .activator__title {
    font-size: var(--activator-title-font-size, var(--sp-ref-font-size-medium));
    font-family: var(--activator-title-font-family, var(--sp-sys-font-family-normal));
    white-space: nowrap;
    position: relative;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
  }
}
.default-filter__activator-icon {
  --fill: var(--sp-sys-color-primary);
}
</style>
