import { watchImmediate } from "@vueuse/core";
import axios from "axios";
import { ref } from "vue";

const dataCache = new Map();
const pendingFetches = new Map();

/**
 * Transform the response data into a Record with the id as the key and the text as the value.
 *
 * @returns {Record<string, string>}
 */
function transformResponse(response) {
  return Object.fromEntries(response.data.map(({ id, text }) => [id, text]));
}

/**
 * Fetch data from a URL.
 * The data is expected to be an array of objects with an id and text field.
 *
 * If the URL is already being fetched, return the pending fetch.
 * If the URL is not being fetched, fetch the data and cache the pending fetch.
 *
 * @param {String} url
 * @returns {Promise<Record<string, string>>} - The fetched and transformed data
 */
async function fetchData(url) {
  if (!pendingFetches.has(url)) {
    const loader = axios
      .get(url)
      .then(transformResponse)
      .finally(() => pendingFetches.delete(url));

    pendingFetches.set(url, loader);
  }

  return pendingFetches.get(url);
}

/**
 * Fetch data from a URL and cache it.
 *
 * @param {String} url
 * @returns {Promise<Record<string, string>|undefined>}
 */
async function getCachedData(url) {
  if (!dataCache.has(url)) {
    dataCache.set(url, await fetchData(url));
  }
  return dataCache.get(url);
}

/**
 * Fetch text from a URL and return the text associated with the id.
 *
 * @param {String} url
 * @param {String|Number} id
 * @returns {Promise<String|undefined>} - The text associated with the id or undefined if the id is not found
 */
async function fetchText(url, id) {
  const data = await getCachedData(url);
  return data?.[id];
}

/**
 * A composable function to fetch text from a URL based on an id.
 * The fetched text is reactive.
 * The text is fetched when the URL or id changes.
 *
 * @param {Object} props - The props object
 * @param {String} props.url - The URL to fetch the data from
 * @param {String|Number} props.id - The key to look up in the fetched data
 *
 * @returns {Record<string, import("vue").Ref>}
 * @property {import("vue").Ref<String>} text - The text associated with the id
 */
export function useRemoteText(props) {
  const text = ref();

  watchImmediate(() => [props.url, props.id], handlePropsChange);

  async function handlePropsChange([url, id]) {
    if (url && id) {
      text.value = await fetchText(url, id);
    }
  }

  return {
    text,
  };
}
