<template>
  <div ref="root" class="sp-stepper">
    <sp-stepper-header>
      <div slot="preamble">
        <slot name="preamble" />
      </div>

      <sp-stepper-item
        v-for="(item, index) in items"
        :key="getItemValue(item)"
        :is-first="index === 0"
        :is-last="index === items.length - 1"
        :active="isActiveIndex(index)"
        :completed="isItemCompleted(item)"
        :title="getItemTitle(item)"
      />
    </sp-stepper-header>

    <sp-stepper-window>
      <div v-for="(_, index) in items" v-show="isActiveIndex(index)" :key="index">
        <slot :name="`item-${index + 1}`" />
      </div>
    </sp-stepper-window>

    <div class="sp-stepper__actions">
      <slot name="actions">
        <sp-stepper-actions v-if="!noActions" :disabled="disabledActions" @prev="prev" @next="next" />
      </slot>
    </div>
  </div>
</template>

<script setup>
import { watchImmediate } from "@vueuse/core";
import { computed, ref, watch } from "vue";
import { useExpose } from "../../composables/expose";
import { toBoolean } from "../../utils/props";

const emit = defineEmits(["update-selected"]);

const props = defineProps({
  items: {
    type: Array,
    default: () => [],
  },
  itemTitle: {
    type: String,
    default: "title",
  },
  itemValue: {
    type: String,
    default: "value",
  },
  itemProps: {
    type: String,
    default: "props",
  },
  completed: {
    type: Object,
    default: () => ({}),
  },
  selected: {
    type: Number,
    default: 0,
  },
  noActions: {
    type: [Boolean, String],
    default: false,
  },
});

const root = ref(null);
const { exposeMethods } = useExpose(root);

exposeMethods({
  prev,
  next,
});

const currentIndex = ref(props.selected);

watch(
  () => props.selected,
  (index) => (currentIndex.value = index),
);

watch(currentIndex, (index) => emit("update-selected", index));

watchImmediate(
  () => props.items,
  () => (currentIndex.value = 0),
);

const noActions = computed(() => toBoolean(props.noActions));

function isActiveIndex(index) {
  return currentIndex.value === index;
}

function isItemCompleted(item) {
  return props.completed?.[getItemValue(item)] ?? getItemProps(item)?.completed ?? false;
}

function getItemTitle(item) {
  return item[props.itemTitle] ?? item;
}

function getItemValue(item) {
  return item[props.itemValue] ?? item;
}

function getItemProps(item) {
  return item[props.itemProps] ?? item;
}

const hasPrev = computed(() => currentIndex.value > 0);
const hasNext = computed(() => currentIndex.value < props.items.length - 1);

const disabledActions = computed(() => {
  if (!hasPrev.value && !hasNext.value) {
    return true;
  }

  if (!hasPrev.value) {
    return "prev";
  }

  if (!hasNext.value) {
    return "next";
  }

  return false;
});

function prev() {
  currentIndex.value = Math.max(0, currentIndex.value - 1);
}

function next() {
  currentIndex.value = Math.min(props.items.length - 1, currentIndex.value + 1);
}
</script>

<style>
:host {
  display: block;
}
</style>

<style scoped lang="scss">
.sp-stepper {
  width: 100%;
  position: relative;
  max-height: var(--sp-comp-stepper-max-height, 90vh);
}
sp-stepper-header {
  position: sticky;
  top: 0;
  z-index: var(--sp-comp-stepper-header-z-index, calc(var(--sp-sys-z-index-max, 99) - 10));
}
</style>
