<template>
  <ReviewSection v-if="purchaseStore.hasPaymentData" title="Payment" :icon="paymentIcon">
    <template v-if="paymentRequired">
      <sp-col>
        <sp-subtitle>{{ paymentName }}</sp-subtitle>
      </sp-col>
      <sp-col cols="2" align-items="end">
        <sp-button variant="text-inline" @click="$emit('goto', 'payment')"> Change </sp-button>
      </sp-col>
    </template>
    <template v-else>
      <sp-col>
        <sp-subtitle> No payment required. </sp-subtitle>
      </sp-col>
    </template>

    <template #footer>
      <sp-row>
        <PaymentSummaryCol />
      </sp-row>
    </template>
  </ReviewSection>
</template>

<script setup>
import { computed } from "vue";
import { usePurchaseStore } from "../store/purchase";
import PaymentSummaryCol from "./PaymentSummaryCol.ce.vue";
import ReviewSection from "./ReviewSection.ce.vue";

const purchaseStore = usePurchaseStore();

defineEmits(["goto"]);

defineProps({
  resource: {
    type: Object,
    default: undefined,
  },
  preamble: {
    type: String,
    default: "Review your data.",
  },
});

const paymentRequired = computed(() => purchaseStore.paymentRequired.value);
const paymentIcon = computed(() => (paymentRequired.value ? purchaseStore.paymentIcon.value : "payment"));
const paymentName = computed(() => purchaseStore.paymentName.value);
</script>
