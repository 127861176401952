<template>
  <div ref="root">
    <sp-alert v-if="!hideError && error" type="error">
      {{ error.message }}
      <p>
        <sub>{{ error.details }}</sub>
      </p>
    </sp-alert>
    <slot name="prepend" />

    <div class="sp-payment-element__payment" :class="classModifiers">
      <slot name="payment">
        <sp-animated-ellipsis color="primary" />
      </slot>
    </div>

    <slot name="append" />

    <slot v-if="!hidePayButton" name="pay" @click.prevent="handlePay">
      <sp-button :loading="loading">Pay</sp-button>
    </slot>
  </div>
</template>

<script setup>
/**
 * This component is a wrapper around the payment form element.
 * It provides a way to render the payment form and handle the submission of the payment form.
 *
 * Note:
 * The payment flow can be initialized with a predefined client secret or by providing the amount, currency, and mode.
 * We want to support both scenarios where the payment intent is created on the server and the client.
 *
 * If the payment form is successfully submitted, it will emit an event with the token.
 *
 * @emits error - Any error that occurs during the payment process
 * @emits success - The payment was successful
 * @emits update-payment-type - The payment type has been updated
 */
import { watchImmediate } from "@vueuse/core";
import { computed, onUnmounted, ref, watch } from "vue";
import { useExpose } from "../../composables/expose";
import { toBoolean } from "../../utils/props";
import { usePayment } from "./composables/payment";
import { PaymentError } from "./lib/error";

const emit = defineEmits(["error", "success", "update-payment-type"]);

const props = defineProps({
  provider: {
    type: String,
    default: "stripe",
  },
  publishableKey: {
    type: String,
    required: true,
    external: true,
  },
  appearance: {
    type: Object,
    default: () => ({}),
    external: true,
  },
  /**
   * The client secret for the payment intent
   * If provided, the payment form will be initialized with the client secret and amount, currency, and mode will be ignored.
   * This is useful for when you want to use a payment intent that was created on the server.
   */
  clientSecret: {
    type: String,
    default: undefined,
  },
  returnUrl: {
    type: String,
    default: undefined,
  },
  hidePayButton: {
    type: [Boolean, String],
    default: false,
  },
  hideError: {
    type: [Boolean, String],
    default: false,
  },
});

const root = ref(null);
const payment = usePayment(root, { ...props, slotName: "payment" });

const error = ref(null);
const loading = ref(false);

const hidePayButton = ref(toBoolean(props.hidePayButton));
watch(
  () => props.hidePayButton,
  () => (hidePayButton.value = toBoolean(props.hidePayButton)),
);

const hideError = ref(toBoolean(props.hideError));
watch(
  () => props.hideError,
  () => (hideError.value = toBoolean(props.hideError)),
);

const { exposeMethods, exposeProperties } = useExpose(root);

exposeMethods({
  validate,
  confirmPayment,
  validateAndConfirm,
});

exposeProperties({
  customValidation: {
    value: true,
  },
});

const element = ref(null);

watchImmediate(() => props.clientSecret, initialize);

onUnmounted(destroy);

watch(payment.paymentType, emitPaymentTypeUpdate);

/**
 * Emit a payment type reset event when the element is destroyed.
 */
watch(element, (value) => {
  if (!value) {
    emitPaymentTypeUpdate(null);
  }
});

function emitPaymentTypeUpdate(value) {
  emit("update-payment-type", value);
}

async function initialize() {
  destroy();

  if (!props.clientSecret) {
    return;
  }

  await createAndMountElement();
}

/**
 * Destroys the payment form element.
 * This is necessary to clean up the payment form element when the component is unmounted.
 * This is important to prevent memory leaks and wrong payment intents being confirmed.
 *
 * @returns {void}
 */
function destroy() {
  element.value?.destroy();
  element.value = null;
}

async function createAndMountElement() {
  const type = "payment";

  const options = {
    clientSecret: props.clientSecret,
    appearance: props.appearance,
  };

  const elementOptions = {
    layout: {
      type: "accordion",
      radios: false,
    },
  };

  element.value = await payment.createAndMountElement(type, options, elementOptions);
}

async function validateAndConfirm() {
  const { error } = await validate();
  if (error) {
    return { error };
  }
  return await confirmPayment();
}

/**
 * Validates the payment form
 *
 * @returns {Promise<{error}>} - An error if the payment form is invalid
 */
async function validate() {
  return await payment.validatePayment();
}

/**
 * Confirms the payment
 *
 * @returns {Promise<Error|void>} - Void if the payment was successful, an error otherwise
 */
async function confirmPayment() {
  const params = {
    return_url: props.returnUrl,
  };

  error.value = null;
  loading.value = true;

  const { error: paymentError } = await payment.confirmPayment(params);

  if (paymentError) {
    error.value = PaymentError.failed(paymentError);
    emit("error", error.value);
  }

  loading.value = false;

  return { error: paymentError };
}

const classModifiers = computed(() => ({
  "--loading": !element.value,
}));
</script>

<style>
:host {
  display: block;
}
sp-button {
  width: 100%;
  margin-top: 1rem;
}
</style>

<style scoped lang="scss">
.sp-payment-element__payment {
  display: block;
  min-height: 12rem;

  &.--loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
