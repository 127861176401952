<template>
  <div ref="root">
    <!-- eslint-disable vue/no-v-html -->
    <div v-if="svgNode" ref="customContainer" :class="classModifiers" class="sp-icon" v-html="svgNode"></div>
    <!-- eslint-enable vue/no-v-html -->

    <component :is="tag" v-else-if="iconHref" v-bind="$attrs" class="icon-wrapper">
      <svg class="icon" :view-box="viewBox" :class="classModifiers">
        <use :href="iconHref" :fill="iconFillColor" />
      </svg>
    </component>

    <div v-else :class="classModifiers" class="sp-icon">
      <slot ref="customContainer" />
    </div>
  </div>
</template>

<script setup>
import { watchImmediate } from "@vueuse/core";
import { computed, onMounted, ref } from "vue";
import { useColorToCssProperty } from "../../composables/color";
import { useSize } from "../../composables/size";
import { fetchIcons } from "../../utils/icons";

const icons = ref(null);
const props = defineProps({
  embedHost: {
    type: String,
    required: false,
    default: undefined,
    external: true,
  },
  name: {
    type: String,
    required: false,
    default: undefined,
  },
  svg: {
    type: String,
    required: false,
    default: undefined,
  },
  url: {
    type: String,
    default: undefined,
  },
  fillColor: {
    type: String,
    default: undefined,
  },
  spriteUrl: {
    type: String,
    external: true,
    default: undefined,
  },
  viewBox: {
    type: String,
    default: "0 0 32 32",
  },
  size: {
    type: String,
    // FIXME: @mgruschetzki: This is not working as expected.
    // validator: (value) =>
    //   ["3xs", "2xs", "1xs", "xs", "s", "m", "l", "xl", "1xl", "2xl", "3xl", "4xl", "5xl"].includes(value),
    default: undefined,
  },
  tag: {
    type: String,
    default: "div",
  },
});

const root = ref(null);
const customContainer = ref(null);

useColorToCssProperty(props, root, { propName: "fillColor", cssProperty: "fill" });

const svgSprite = computed(() => icons.value?.[props.name]);
const svgNode = computed(() => props.svg ?? svgSprite.value);

const classNames = computed(() => ({ [`icon--${props.name}`]: props.name, "theme--dark": props.dark }));

const iconSpriteUrl = computed(() => props.spriteUrl ?? window.iconSpriteUrl?.());
const iconHref = computed(() => {
  if (!iconSpriteUrl.value || !props.name) {
    return undefined;
  }
  return `${iconSpriteUrl.value}#icon-${props.name}`;
});

const { sizeModifiers } = useSize(props);
const classModifiers = computed(() => [classNames.value, ...sizeModifiers.value]);

/**
 * Returns the element that should be used to set the fill color.
 * If the custom container is a slot, the first element in the slot is returned.
 * Otherwise the custom container itself is returned.
 *
 * @type {HTMLElement}
 */
const customContainerElement = computed(() => {
  if (!customContainer.value) {
    return null;
  }
  const elements = customContainer.value.assignedNodes?.()?.filter(({ nodeType }) => nodeType === Node.ELEMENT_NODE);
  return elements?.at(0) ?? customContainer.value;
});

/**
 * Sets the fill color on all paths and uses of the icon.
 * If the icon is not filled, nothing is done.
 * If the icon is filled, the fill color is set on all paths and uses.
 * If the fill color is undefined, the fill color is removed from all paths and uses.
 *
 * @param {String|undefined} fillColor - The fill color to set.
 */
function setFillColor(fillColor) {
  const container = customContainerElement.value;

  if (!container?.querySelector?.(".icon__use--filled")) {
    return;
  }

  container.querySelectorAll("path, use").forEach((path) => {
    if (fillColor) {
      path.setAttribute("fill", props.fillColor);
    } else {
      path.removeAttribute("fill");
    }
  });
}

watchImmediate(
  () => props.fillColor,
  (fillColor) => setFillColor(fillColor),
);

watchImmediate(
  () => props.url,
  () => fetchIconIfAvailable(),
);

async function fetchIconIfAvailable() {
  if (iconSpriteUrl.value && props.name) {
    return;
  } else if (props.name) {
    icons.value = await fetchIcons(props.embedHost);
  } else if (props.url) {
    const response = await fetch(props.url);
    customContainer.value.innerHTML = await response.text();
  }
}

onMounted(async () => {
  await fetchIconIfAvailable();

  if (props.fillColor) {
    setFillColor(props.fillColor);
  }
});
</script>

<style>
:host {
  --fill: var(--sp-sys-icon-fill, black);
  --size: var(--sp-sys-icon-size-m, 2rem);
  --font-size: var(--sp-icon-font-size, 1rem);
  display: block;
  font-size: var(--font-size);
}

::slotted(svg),
svg {
  height: var(--size);
  width: auto;
  width: var(--size);
  fill: var(--fill);
}
</style>

<style lang="scss" scoped>
.sp-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: var(--size);
  width: var(--size);
  max-height: var(--size);
  max-width: var(--size);

  // Sizes
  @each $size in (3xs 2xs xs s m l xl 2xl 3xl 4xl 5xl) {
    &.--size-#{$size} {
      --size: var(--sp-sys-icon-size-#{$size}, 2rem);
    }
  }
}

.icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  --icon-size: var(--size);
}
</style>
