<template>
  <div class="sp-container">
    <slot />
  </div>
</template>
<style>
:host {
  display: block;
  width: 100%;
}
</style>
