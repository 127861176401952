<template>
  <sp-icon name="chevron-down" class="sp-caret" :class="classModifiers" :fill-color="color" />
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  active: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: "32",
  },
  color: {
    type: String,
    default: "currentColor",
  },
  transitionRotate: {
    type: Boolean,
    default: true,
  },
  direction: {
    type: String,
    validator: (value) => ["up", "down", "left", "right"].includes(value),
    default: "down",
  },
});

const classModifiers = computed(() => ({
  "--is-active": props.active,
  "--transition-rotate": props.transitionRotate,
  [`--dir-${props.direction}`]: props.direction,
}));
</script>

<style>
:host {
  display: inline-block;
  --caret-size: 2rem;
  max-height: var(--caret-size);
  max-width: var(--caret-size);
  transform: translateY(-0.125rem);
}
</style>

<style lang="scss" scoped>
.sp-caret {
  fill: var(--caret-color);

  &.--transition-rotate {
    transition: transform 0.2s ease-in-out;
  }

  &.--transition-rotate.--is-active {
    transform: rotate(-180deg);
  }

  &.--dir-down {
    transform: rotate(0);
  }

  &.--dir-up {
    transform: rotate(-180deg);
  }

  &.--dir-left {
    transform: rotate(90deg);
  }

  &.--dir-right {
    transform: rotate(-90deg);
  }
}
</style>
