export const props = Object.freeze({
  /**
   * Creates a sp-icon component before default content in the prepend slot.
   *
   * @type {String}
   * @default undefined
   */
  prependIcon: {
    type: String,
    default: undefined,
  },
  /**
   * Creates a sp-icon component after default content in the append slot.
   *
   * @type {String}
   * @default undefined
   */
  appendIcon: {
    type: String,
    default: undefined,
  },
  /**
   * Removes the ability to click or target the input.
   *
   * @type {Boolean}
   * @default false
   */
  disabled: {
    type: [Boolean, String],
    default: false,
  },
  /**
   * Removes the ability to edit the input’s value.
   *
   * @type {Boolean}
   * @default false
   */
  readonly: {
    type: [Boolean, String],
    default: false,
  },
  /**
   * Sets the input’s inputmode attribute.
   *
   * @type {String}
   * @default undefined
   */
  inputmode: {
    type: String,
    default: undefined,
    validator: (value) => {
      const validValues = ["none", "text", "decimal", "numeric", "tel", "search", "email", "url"];
      return validValues.includes(value);
    },
  },
  /**
   * The model value of the component.
   *
   * @type {any}
   * @default null
   */
  value: {
    type: [String, Number, Boolean, Object, Array],
    default: null,
  },
  /**
   * Sets the input’s placeholder text.
   *
   * @type {String}
   * @default undefined
   */
  placeholder: {
    type: String,
    default: undefined,
  },
  /**
   * Sets the input’s type attribute.
   *
   * @type {String}
   * @default "text"
   */
  type: {
    type: String,
    default: "text",
  },
  /**
   * Sets the input’s loading state.
   * If true, shows a linear progress bar.
   *
   * @type {Boolean}
   * @default false
   */
  loading: {
    type: [Boolean, String],
    default: false,
  },
  /**
   * Allows for the component to be cleared.
   *
   * @type {Boolean}
   * @default false
   */
  clearable: {
    type: [Boolean, String],
    default: false,
  },
  /**
   * Applied when using clearable and the input is dirty.
   *
   * @type {String}
   * @default "close"
   */
  clearIcon: {
    type: String,
    default: "close-filled",
  },
  clearIconFill: {
    type: String,
    default: "currentColor",
  },
  /**
   * Always show the clearable icon when the input is dirty (By default it only shows on hover).
   *
   * @type {Boolean}
   * @default false
   */
  persistentClear: {
    type: [Boolean, String],
    default: false,
  },
});
