<template>
  <div class="sp-card-actions">
    <slot />
  </div>
</template>

<script setup></script>

<style>
:host {
  display: block;
}
</style>

<style lang="scss" scoped>
.sp-card-actions {
  display: flex;
  padding-inline: var(--sp-card-actions-padding-inline, var(--sp-comp-card-padding-inline, 1rem));
  padding-block: var(--sp-card-actions-padding-block, var(--sp-comp-card-padding-block, 0.5rem));
}
</style>
