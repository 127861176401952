import axios from "axios";

const basePath = "/assets/icons.json";

let icons = null;
let fetchPromise = null;

export async function fetchIcons(host) {
  if (icons) {
    return icons;
  }

  if (!fetchPromise) {
    const url = host ? `${host}${basePath}` : basePath;

    fetchPromise = axios
      .get(url)
      .then((response) => {
        icons = response.data;
        return icons;
      })
      .finally(() => {
        fetchPromise = null; // Reset the fetchPromise after completion
      });
  }

  return fetchPromise;
}
