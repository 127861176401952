<template>
  <component :is="tag" v-if="text" class="sp-remote-text" part="text">
    {{ text }}
  </component>
</template>

<script setup>
/**
 * This component fetches text from a remote URL and renders it.
 *
 * @example
 * <sp-remote-text id="my-text" url="/api/text/my-text" />
 *
 * The above example will fetch the text data from the URL /api/text/my-text and render
 * the text value associated with the key "my-text".
 *
 * If the text is not found, the component will silently render nothing.
 *
 * TODO: Add error handling.
 * TODO: Add loading state.
 * TODO: Allow for text to be fetched from different data structures.
 */
import { useRemoteText } from "./composables/remote-text";

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  url: {
    type: String,
    required: true,
  },
  tag: {
    type: String,
    default: "div",
  },
});

const { text } = useRemoteText(props);
</script>
