import { onMounted, ref } from "vue";

const maps = ref();

function loadGoogleMaps(key) {
  const url = new URL("https://maps.googleapis.com/maps/api/js");
  url.searchParams.append("key", key);
  url.searchParams.append("libraries", "places,geometry");

  const script = document.createElement("script");
  script.src = url.href;
  script.async = true;
  script.defer = true;
  script.onload = initGoogleMaps;

  document.head.appendChild(script);
}

function initGoogleMaps() {
  maps.value = window.google.maps;
}

export function useGoogleMaps(props) {
  onMounted(loadGoogleMapsIfNotLoaded);

  function loadGoogleMapsIfNotLoaded() {
    if (!maps.value) {
      loadGoogleMaps(props.apiKey);
    }
  }

  return {
    maps,
  };
}
