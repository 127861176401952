import { ref } from "vue";

const error = ref();
const isHiddenTemporarily = ref(false);

export function useState() {
  function setError(message) {
    error.value = message;
  }

  function clearError() {
    error.value = undefined;
  }

  return {
    error,
    isHiddenTemporarily,

    setHideTemporarily: (value) => (isHiddenTemporarily.value = value),

    setError,
    clearError,
  };
}
