<template>
  <Transition>
    <sp-container v-if="visible" class="expiry-alert">
      <sp-row align="center">
        <sp-col align-self="center" align-items="center">
          <sp-text type="h2">{{ title }}</sp-text>
          <sp-text>{{ message }}</sp-text>
          <sp-button :loading="loading" @click="handleButtonClick">{{ buttonlabel }}</sp-button>
        </sp-col>
      </sp-row>
    </sp-container>
  </Transition>
</template>

<script setup>
import { computed, ref } from "vue";
import { usePurchaseStore } from "../store/purchase";

const loading = ref(false);

const emit = defineEmits(["close"]);

const { isExpired, expiresWithinSeconds, secondsUntilExpiration, extendExpiration } = usePurchaseStore();

const visible = computed(() => isExpired.value || expiresWithinSeconds.value);

const title = computed(() => (isExpired.value ? "Session Expired." : "Are you still there?"));

const message = computed(() => {
  if (isExpired.value) {
    return "Your checkout session ended due to inactivity. Close this window to start over.";
  }

  return `Your checkout session will expire in ${secondsUntilExpiration.value} seconds unless you choose to continue.`;
});

const buttonlabel = computed(() => (isExpired.value ? "Close" : "Continue"));

async function handleButtonClick() {
  if (isExpired.value) {
    return emit("close");
  }
  loading.value = true;
  await extendExpiration();
  loading.value = false;
}
</script>

<style lang="scss" scoped>
.expiry-alert {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.95);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

sp-row {
  height: 100%;
}

sp-col {
  gap: 2rem;
  max-width: 40ch;
}

sp-button {
  --button-min-width: 18rem;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.7s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
