import { watchImmediate } from "@vueuse/core";
import { computed } from "vue";
import { useModifiers } from "./classes";
import { useHost } from "./host";

export const systemColors = ["primary", "secondary", "tertiary", "success", "warning", "danger", "info"];

export function isSystemColor(color) {
  return systemColors.includes(color);
}

function applyColorProperty(color, host, cssProperty) {
  if (color && host) {
    host.style.setProperty(`--${cssProperty}`, isSystemColor(color) ? `var(--sp-sys-color-${color})` : color);
  }
}

export function useColorToCssProperty(props, root, options) {
  const { host } = useHost(root);
  const { propName = "color", cssProperty = "color" } = options ?? {};

  watchImmediate(
    () => [props[propName], host.value],
    ([color, host]) => applyColorProperty(color, host, cssProperty),
    options,
  );
}

export function useColor(props) {
  const { modifiers } = useModifiers(props, "color");
  const colorModifiers = computed(() => modifiers.value);

  return {
    colorModifiers,
  };
}
