<template>
  <component :is="tag" class="sp-button" :class="classModifiers" part="base" :disabled="disabled">
    <div ref="prependSlot" class="sp-button__prepend" :class="prependSlotClassModifiers">
      <slot name="prepend" />
    </div>
    <div class="sp-button__content" part="content">
      <div v-if="loading" class="sp-button__loading">
        <sp-animated-ellipsis></sp-animated-ellipsis>
      </div>
      <div v-if="icon" class="sp-button__icon" part="icon">
        <sp-icon part="icon" :name="icon" :size="size" />
      </div>
      <div v-else class="sp-button__label" part="label">
        <slot>
          {{ label }}
        </slot>
      </div>
    </div>
    <div ref="appendSlot" class="sp-button__append" :class="appendSlotClassModifiers">
      <slot name="append" />
    </div>
  </component>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import { useBooleanModifiers } from "../../composables/classes";
import { useColor } from "../../composables/color";
import { useSize } from "../../composables/size";
import { useVariant } from "../../composables/variant";
import { toBoolean } from "../../utils/props";
import { hasSlotContent } from "../../utils/slots";

const props = defineProps({
  tag: {
    type: String,
    default: "button",
    validator: (value) => ["button", "a"].includes(value),
  },
  label: {
    type: String,
    default: undefined,
  },
  color: {
    type: String,
    default: "primary",
  },
  size: {
    type: String,
    validator: (value) => ["xx-small", "x-small", "small", "medium", "large", "default"].includes(value),
    default: "default",
  },
  variant: {
    type: String,
    validator: (value) =>
      ["text", "text-inline", "appearance-link", "flat", "elevated", "outlined", "plain"].includes(value),
    default: "flat",
  },
  /**
   * Change the button to a specific variant on small screens.
   *
   * @type {String}
   * @default undefined
   */
  smVariant: {
    type: String,
    validator: (value) =>
      ["text", "text-inline", "appearance-link", "flat", "elevated", "outlined", "plain"].includes(value),
    default: undefined,
  },

  /**
   * Disable the button.
   *
   * @type {Boolean|String}
   * @default false
   */
  disabled: {
    type: [Boolean, String],
    default: false,
  },
  /**
   * Apply a specific icon using the sp-icon component.
   * The button will become round.
   *
   * @type {String|Boolean}
   * @default false
   */
  icon: {
    type: [String, Boolean],
    default: false,
  },
  /**
   * Show a loading spinner.
   *
   * @type {Boolean|String}
   * @default false
   */
  loading: {
    type: [Boolean, String],
    default: false,
  },
  /**
   * Block the button.
   *
   * @type {Boolean|String}
   * @default false
   */
  block: {
    type: [Boolean, String],
    default: false,
  },
  /**
   * Stack the button prepend content and append.
   *
   * @type {Boolean|String}
   * @default false
   */
  stacked: {
    type: [Boolean, String],
    default: false,
  },
});

const { colorModifiers } = useColor(props);
const { variantModifiers } = useVariant(props);
const { sizeModifiers } = useSize(props);
const { booleanModifiers } = useBooleanModifiers(props, "icon", "block", "stacked", "loading");
const disabled = ref(toBoolean(props.disabled));

watch(
  () => props.disabled,
  (value) => (disabled.value = toBoolean(value)),
);

const loading = ref(toBoolean(props.loading));
watch(
  () => props.loading,
  (value) => {
    loading.value = toBoolean(value);
  },
);

const classModifiers = computed(() => [
  ...colorModifiers.value,
  ...variantModifiers.value,
  ...sizeModifiers.value,
  ...booleanModifiers.value,
]);

const prependSlot = ref(null);
const hasPrependSlot = computed(() => hasSlotContent(prependSlot.value));
const prependSlotClassModifiers = computed(() => createContentClassModifier(hasPrependSlot.value));

const appendSlot = ref(null);
const hasAppendSlot = computed(() => hasSlotContent(appendSlot.value));
const appendSlotClassModifiers = computed(() => createContentClassModifier(hasAppendSlot.value));

function createContentClassModifier(slot) {
  return { "--has-content": slot };
}
</script>

<style>
:host {
  --bg-color: var(--sp-ce-button-bg-color, #fefefe);
  --text-color: var(--sp-ce-button-text-color, currentColor);
  --icon-fill-color: var(--sp-ce-button-icon-fill-color, var(--text-color));
  --hover-bg-color: var(--sp-ce-button-hover-bg-color, transparent);
  --hover-bg-opacity: var(--sp-ce-button-hover-bg-opacity, 1);
  --border-color: var(--sp-ce-button-border-color, transparent);
  --hover-border-color: var(--sp-ce-button-hover-border-color, var(--border-color, transparent));
  --border-width: var(--sp-ce-button-border-width, 0);
  --border-style: var(--sp-ce-button-border-style, solid);
  --button-border-radius: var(--sp-ce-button-border-radius, 0);
  --transition-time: 0.3s;
  --font-family: var(--sp-ce-button-font-family, inherit);
  --font-weight: var(--sp-ce-button-font-weight, normal);
  --button-display: var(--sp-ce-button-display, inline-grid);
  --button-min-width: var(--sp-ce-button-min-width, minmax(fit-content, 9rem));
  --button-width: var(--sp-ce-button-width, auto);
  --size: var(--sp-ce-button-size, 2.75rem);
  --button-min-height: var(--sp-ce-button-min-height, var(--size));
  --button-justify-content: var(--sp-ce-button-justify-content, center);
  --button-content-text-align: var(--sp-ce-button-content-text-align, center);
  --button-content-wrap: var(--sp-ce-button-content-wrap, normal);
  --button-content-text-overflow: var(--sp-ce-button-content-text-overflow, ellipsis);
  --button-content-overflow: var(--sp-ce-button-content-overflow, visible);
  --button-gap: var(--sp-ce-button-gap, 0.5ch);
  --button-padding-inline: var(--sp-ce-button-padding-inline, 0);
  --button-padding-block: var(--sp-ce-button-padding-block, 1rem);
  --button-padding: var(--sp-ce-button-padding, var(--button-padding-inline) var(--button-padding-block));
  display: inline-grid;
  width: var(--button-width);
  box-sizing: border-box;
  border-radius: var(--button-border-radius);
  border-width: 0;
}

:host([variant="text-inline"]),
:host([variant="appearance-link"]) {
  display: inline;
  --button-display: inline;
}

:host([block]) {
  display: grid;
  min-width: 100%;
}
</style>

<style lang="scss" scoped>
.sp-button {
  align-items: center;
  background: var(--bg-color);
  border-color: var(--border-color);
  border-radius: var(--button-border-radius);
  border-style: var(--border-style);
  border-width: var(--border-width);
  box-sizing: border-box;
  color: var(--text-color);
  cursor: pointer;
  display: var(--button-display);
  font-family: var(--font-family);
  font-size: var(--sp-ce-button-font-size, var(--sp-sys-body-font-size, 1rem));
  font-weight: var(--font-weight);
  grid-template-areas: "prepend content append";
  grid-template-columns: max-content auto max-content;
  height: var(--size);
  min-height: var(--button-min-height);
  justify-content: var(--button-justify-content);
  min-width: var(--button-min-width);
  width: var(--button-width);
  padding: var(--button-padding);
  position: relative;
  text-decoration: none;
  text-transform: var(--sp-ce-button-text-transform, none);

  // ::before controls the background hover
  // ::after controls the focus
  &::before,
  &::after {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    border-radius: inherit;
    box-sizing: border-box;
    transition: opacity var(--transition-time) linear;
  }

  // Element for Background hover
  &::before {
    background: var(--hover-bg-color);
  }

  &:hover:not([disabled]) {
    color: var(--hover-text-color);
    border-color: var(--hover-border-color);

    &::before {
      opacity: var(--hover-bg-opacity);
    }
  }

  &.--variant-outlined {
    --border-width: var(--sp-ce-button-outlined-border-width, 1px);
    --border-style: var(--sp-ce-button-outlined-border-style, solid);
    --border-color: var(--sp-ce-button-outlined-border-color, var(--button-bg-color)) !important;
  }

  &.--variant-text {
    --border-width: 0;
  }

  &.--variant-text-inline {
    &::after,
    &::before {
      top: -0.75ex;
      right: -0.5ch;
      bottom: -0.75ex;
      left: -0.5ch;
    }
  }
  &.--variant-text-inline,
  &.--variant-appearance-link {
    --button-min-height: auto;
    padding: 0 !important;
    height: auto !important;
    min-width: 0 !important;
    gap: 0 !important;
  }

  &.--variant-appearance-link {
    --bg-color: transparent !important;
    --hover-bg-opacity: var(--sp-ce-button-appearance-link-hover-bg-opacity, 0);
    --text-color: var(--sp-ce-button-appearance-link-text-color, var(--sp-sys-color-link, #007078)) !important;
    --hover-text-color: var(--sp-ce-button-appearance-link-hover-text-color, var(--text-color)) !important;
    --border-color: transparent;
    --border-width: 0;
    --border-style: none;
    --button-display: inline;
    text-decoration: var(--sp-ce-button-appearance-link-text-decoration, underline);

    &::before,
    &::after {
      display: none;
    }
  }

  &:not([disabled="false"])[disabled] {
    cursor: var(--sp-ce-button-disabled-cursor, default);
    opacity: var(--sp-ce-button-disabled-opacity, 0.3);
    --disabled-text-color: var(--sp-ce-button-disabled-text-color, var(--text-color));
    color: var(--disabled-text-color);
  }

  &.--block {
    width: 100%;
  }

  &.--stacked {
    grid-template-areas: "prepend" "content" "append";
    justify-items: center;
    min-height: fit-content;
    height: auto;

    .sp-button__content,
    .sp-button__label,
    .sp-button__icon {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  &.--loading {
    cursor: wait;
    .sp-button__label,
    .sp-button__icon {
      opacity: 0;
    }
  }

  // Sizes
  &.--size-xx-small {
    --size: var(--sp-ce-button-xx-small-height, 1.25rem);
    font-size: var(--sp-ce-button-xx-small-font-size, 0.5rem);
    padding: var(--sp-ce-button-xx-small-padding, 0 0.25rem);
  }

  &.--size-x-small {
    --size: var(--sp-ce-button-x-small-height, 1.5rem);
    font-size: var(--sp-ce-button-x-small-font-size, 0.75rem);
    padding: var(--sp-ce-button-x-small-padding, 0 0.5rem);
  }

  &.--size-small {
    --size: var(--sp-ce-button-small-height, 2rem);
    font-size: var(--sp-ce-button-small-font-size, 0.875rem);
    padding: var(--sp-ce-button-small-padding, 0 0.75rem);
  }

  &.--size-large {
    --size: var(--sp-ce-button-large-height, 3.75rem);
    font-size: var(--sp-ce-button-large-font-size, 1.375rem);
    padding: var(--sp-ce-button-large-padding, 0 1.875rem);
  }

  &.--icon {
    --button-content-overflow: hidden;
    --button-border-radius: 50%;
    --icon-size: var(--size);
    padding: 0;
    min-width: var(--size);
    width: var(--size);
    min-height: var(--size);
    max-height: var(--size);
    justify-content: center;
    align-items: center;
    grid-template-areas: "content";
    grid-template-columns: auto;
    grid-template-rows: auto;

    sp-icon {
      --size: var(--icon-size);
      --fill: var(--text-color);
    }
    .sp-button__prepend,
    .sp-button__append {
      display: none;
    }
  }

  // Colors
  @each $color in primary, secondary, danger, text, error, warning, success, info, "white" {
    &.--color-#{$color} {
      --text-color: var(--sp-ce-button-#{$color}-text-color, var(--sp-sys-color-on-#{$color}, #fff));
      --bg-color: var(--sp-ce-button-#{$color}-bg-color, var(--sp-sys-color-#{$color}, #666));
      --hover-text-color: var(--sp-ce-button-#{$color}-hover-text-color, #fff);
      --hover-bg-color: var(--sp-ce-button-#{$color}-hover-bg-color, var(--bg-color));

      &.--variant-text-inline,
      &.--variant-text {
        --bg-color: transparent;
        --text-color: var(
          --sp-ce-button-#{$color}-variant-text-text-color,
          var(--sp-ce-button-#{$color}-bg-color, var(--sp-sys-color-#{$color}, #666))
        );
        --hover-text-color: var(--sp-ce-button-#{$color}-hover-variant-text-text-color, var(--text-color));
        --hover-bg-color: var(
          --sp-ce-button-#{$color}-hover-outlined-bg-color,
          var(--sp-ce-button-#{$color}-bg-color, var(--sp-sys-color-#{$color}, #666))
        );
        --hover-bg-opacity: var(--sp-ce-button-#{$color}-hover-variant-text-bg-opacity, 0.1);
      }

      &.--variant-outlined {
        --bg-color: transparent;
        --text-color: var(
          --sp-ce-button-#{$color}-outlined-text-color,
          var(--sp-ce-button-#{$color}-bg-color, var(--sp-sys-color-#{$color}, #666))
        );

        --border-color: var(
          --sp-ce-button-#{$color}-outlined-border-color,
          var(--sp-ce-button-#{$color}-bg-color, var(--sp-sys-color-#{$color}, #666))
        );
        --hover-text-color: var(
          --sp-ce-button-#{$color}-hover-outlined-text-color,
          var(--sp-ce-button-#{$color}-text-color, #fff)
        );
        --hover-bg-color: var(
          --sp-ce-button-#{$color}-hover-outlined-bg-color,
          var(--sp-ce-button-#{$color}-bg-color, var(--sp-sys-color-#{$color}, #666))
        );
        --hover-border-color: var(
          --sp-ce-button-#{$color}-outlined-hover-border-color,
          var(--sp-ce-button-#{$color}-bg-color, var(--sp-sys-color-#{$color}, #666))
        );
        &:hover {
          --icon-fill-color: var(--hover-text-color);
        }
      }
    }
  }

  &:focus,
  &:focus-visible,
  &:focus-within {
    outline: none;
    box-shadow: none;
  }

  &:focus,
  &:focus-within {
    outline: none;
    box-shadow: none;

    &::after {
      opacity: var(--sp-ce-button-focus-opacity, 1);
      outline: var(--sp-ce-button-focus-outline, 1px solid #ccc);
      box-shadow: var(--sp-ce-button-focus-box-shadow, none);
    }
  }

  // enable special syling for key-focus if needed
  &:focus-visible::after {
    box-shadow: var(--sp-ce-button-focus-visible-box-shadow, var(--sp-ce-button-focus-box-shadow, none));
    outline: var(--sp-ce-button-focus-visible-outline, var(--sp-ce-button-focus-outline, 1px solid #ccc));
    opacity: var(--sp-ce-button-focus-visible-opacity, var(--sp-ce-button-focus-opacity, 1));
  }
}

.sp-button__content {
  grid-area: content;
  z-index: 1;
  text-align: var(--button-content-text-align);
  overflow: var(--button-content-overflow);
}

.sp-button__prepend,
.sp-button__append {
  position: relative;
  z-index: 1;
}

.sp-button__prepend {
  grid-area: prepend;
  left: calc(var(--button-gap) * -1);

  & ::slotted(sp-icon) {
    --fill: var(--sp-ce-button-prepend-icon-fill, var(--icon-fill-color));
    --size: var(--sp-ce-button-prepend-icon-size, 2rem);
  }
}

.sp-button__append {
  grid-area: append;
  right: calc(var(--button-gap) * -1);

  & ::slotted(sp-icon) {
    --fill: var(--sp-ce-button-append-icon-fill, var(--icon-fill-color));
    --size: var(--sp-ce-button-append-icon-size, 2rem);
  }
}
.sp-button__loading {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
}
</style>
