import { BaseApi } from "../../../../shared/api/base";

export class DistanceFilterApi extends BaseApi {
  constructor({ baseURL }) {
    super({ baseURL, root: "common_places" });
  }
}

export const useDistanceFilterApi = ({ url }) => {
  const api = new DistanceFilterApi({ baseURL: url });

  return {
    getCommonPlaces: async () => api.get(""),
  };
};
