<template>
  <sp-row v-for="(lineItem, index) in lineItems" :key="index" class="line-item-row">
    <sp-col>
      <sp-subtitle>{{ lineItem.name }}</sp-subtitle>
    </sp-col>

    <sp-col align-items="end">
      <span class="amount">{{ lineItem.amount }}</span>
    </sp-col>
  </sp-row>
</template>

<script setup>
import { usePurchaseStore } from "../store/purchase";

const { lineItems } = usePurchaseStore();
</script>

<style lang="scss" scoped>
.line-item-row {
  margin-block: var(--sp-ref-spacing-4) calc(var(--sp-ref-spacing-4) * 3);
}
.amount {
  font-size: var(--sp-ref-font-size-small);
}
</style>
