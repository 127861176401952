<template>
  <ReviewSection :title="title" icon="event-color">
    <sp-col sm="9">
      <sp-subtitle>{{ event.name }}</sp-subtitle>
      <span>{{ event.formatted_date_range }}</span>
      <span>{{ event.formatted_time_range }}</span>
    </sp-col>
    <sp-col sm="10" offset-sm="2">
      <template v-if="event.is_in_person || event.is_virtual_and_in_person">
        <div v-if="event.is_virtual_and_in_person">
          <sp-subtitle>Virtual or In-Person</sp-subtitle>
        </div>

        <template v-if="event.address">
          <sp-subtitle>{{ event.address.locale_name }}</sp-subtitle>
          <span>{{ event.address.street_address }}</span>
          <div>
            {{ event.address.city }}, {{ event.address.state }}
            {{ event.address.zip_code }}
          </div>
        </template>
      </template>
      <template v-else-if="event.is_virtual">
        <sp-subtitle>Virtual Event</sp-subtitle>
      </template>
    </sp-col>
    <sp-col cols="2">
      <sp-spacer />
    </sp-col>
  </ReviewSection>
</template>

<script setup>
import { computed } from "vue";
import ReviewSection from "./ReviewSection.ce.vue";

defineEmits(["goto"]);

const props = defineProps({
  title: {
    type: String,
    default: "Date & Time",
  },
  resource: {
    type: Object,
    required: true,
  },
});

const event = computed(() => props.resource.event);
</script>
