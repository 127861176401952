<template>
  <sp-col cols="6" sm="9">
    <sp-text-field
      :value="model"
      clearable
      persistent-clear
      placeholder="Enter promo code"
      @input="handleTextInput"
      @keydown.enter.stop="applyPromoCode"
      @clear="resetModel"
    ></sp-text-field>
    <sp-alert v-if="error" type="error" variant="text" compact>
      {{ error }}
    </sp-alert>
  </sp-col>

  <sp-col self-align="center" cols="3" no-start-gap>
    <sp-button :disabled="!promoCodeApplicable" :loading="loading" @click="applyPromoCode">Apply</sp-button>
  </sp-col>
</template>

<script setup>
import { computed, ref } from "vue";
import { usePurchaseStore } from "../store/purchase";

const purchaseStore = usePurchaseStore();

const model = ref();
const error = ref();
const loading = ref(false);

const promoCodeApplicable = computed(() => model.value && model.value.length >= 3);

async function applyPromoCode() {
  loading.value = true;
  error.value = undefined;

  const { error: applyError } = await purchaseStore.applyPromoCode(model.value);
  loading.value = false;
  error.value = applyError;

  if (!applyError) {
    model.value = undefined;
  }
}

function handleTextInput({ detail }) {
  const [value] = detail;
  model.value = value;
  error.value = undefined;
}

function resetModel() {
  model.value = undefined;
}
</script>
