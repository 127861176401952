import { unref } from "vue";

export function getSlot(container) {
  const firstChild = container?.firstElementChild;

  if (!firstChild || firstChild.tagName !== "SLOT") {
    return undefined;
  }

  return firstChild;
}

export function getSlotContent(slotOrContainer) {
  const slot = getSlot(unref(slotOrContainer));
  if (slot) {
    return slot.assignedElements();
  }

  return Array.from(unref(slotOrContainer)?.children ?? []);
}

export function hasSlotContent(slot) {
  const content = getSlotContent(slot);
  return content !== undefined && content.length > 0;
}
