import { useStripe } from "./stripe";

/**
 * Payment composable
 * The payment composable is a factory function that returns a payment composable based on the provider.
 *
 * @param {Object} config
 * @param {String} provider - the payment provider
 * @param {String} config.publishableKey - api key
 *
 * @throws {Error} - If provider is not supported
 */
export function usePayment(host, config) {
  const { provider } = config;

  if (provider === "stripe") {
    return useStripe(host, config);
  }

  throw new Error(`Unsupported payment provider: ${provider}`);
}
