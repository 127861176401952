<template>
  <div class="sp-stepper-window">
    <slot />
  </div>
</template>

<style>
:host {
  display: block;
}
</style>
