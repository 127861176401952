<template>
  <sp-col flex-direction="row" align-items="center">
    Promo code:
    <strong>
      {{ promoCode }}
    </strong>
    <sp-button
      class="clear-button"
      icon="close"
      color="surface"
      size="small"
      :loading="loading"
      @click="removePromoCode"
    ></sp-button>
  </sp-col>
  <sp-col cols="4" align-items="end" align-self="center">
    <span v-if="hasDiscount" class="discount">{{ discountAmount }}</span>
  </sp-col>
</template>

<script setup>
import { computed, ref } from "vue";
import { usePurchaseStore } from "../store/purchase";

const purchaseStore = usePurchaseStore();
const promoCode = computed(() => purchaseStore.promoCode.value);

const discountAmount = computed(() => purchaseStore.discountAmount.value);
const hasDiscount = computed(() => purchaseStore.hasDiscount);

const error = ref();
const loading = ref(false);

async function removePromoCode() {
  loading.value = true;
  error.value = undefined;

  const { error: removeError } = await purchaseStore.removePromoCode();
  loading.value = false;
  error.value = removeError;
}
</script>

<style lang="scss" scoped>
.discount {
  font-size: var(--sp-ref-font-size-small);
  font-family: var(--sp-sys-font-family-medium);
  font-weight: var(--sp-sys-font-weight-medium);
}

.clear-button {
  transition: all 0.2s;
  opacity: 1;
  &:hover {
    transform: scale(1.3);
    opacity: 0.8;
  }
}

.clear-button::part(icon) {
  --fill: var(--sp-sys-color-primary);
}
</style>
