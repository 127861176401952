<template>
  <div ref="root" class="sp-modal" :class="classModifiers">
    <slot name="activator" @click.stop="open = true" />

    <component
      :is="contentComponent"
      v-bind="$props"
      :open="open"
      :scrollable="false"
      :width="width"
      :is-hidden-temporarily="isHiddenTemporarily"
      :no-content-padding="noMobileContentPadding"
      expand-height-full
      @close="handleDialogClose"
      @update-open="handleDialogUpdateOpen"
    >
      <sp-card class="sp-modal__card" :subtitle="subtitle" :scrollable-content="scrollableContent" grid>
        <div slot="title" class="sp-modal__card-title">
          <slot name="title">
            <sp-card-title v-if="modalTitle" oneline>{{ modalTitle }}</sp-card-title>
          </slot>

          <sp-button
            v-if="showCloseButton"
            icon="close-bold"
            size="small"
            class="close-button"
            color="default"
            @click="handleDialogClose"
          >
          </sp-button>
        </div>

        <div slot="content-overlay">
          <slot name="content-overlay" />
        </div>

        <div slot="subtitle">
          <slot name="subtitle" />
        </div>

        <sp-card-content>
          <div class="sp-modal-alert-container">
            <div class="sp-modal__alert">
              <slot name="alert" />
            </div>
          </div>

          <slot />
        </sp-card-content>

        <sp-card-actions slot="actions">
          <slot name="actions" />
        </sp-card-actions>
      </sp-card>
    </component>
  </div>
</template>

<script setup>
/**
 * The `sp-modal` component is a wrapper around sp-dialog that provides a default layout for the dialog content.
 * It includes a header with a title and a close button, a subtitle, and a slot for the main content.
 * It also provides a slot for the actions that will be displayed at the bottom of the dialog.
 * It also provides a slot for the activator element that will open the dialog when clicked.
 *
 * @slot activator - The element that will open the dialog when clicked.
 * @slot modalTitle - The title of the dialog.
 * @slot subtitle - The subtitle of the dialog.
 * @slot actions - The actions that will be displayed at the bottom of the dialog.
 *
 * @emits close - Emitted when the dialog is closed.
 * @emits update-open - Emitted when the open property is updated.
 */
import { computed, ref, watch } from "vue";
import { useBreakpoints } from "../../composables/breakpoints";
import { toBoolean } from "../../utils/props";
import { props as dialogProps } from "../SpDialog/props.js";

const emit = defineEmits(["close", "update-open"]);

const props = defineProps({
  ...dialogProps,
  modalTitle: {
    type: String,
    default: undefined,
  },
  subtitle: {
    type: String,
    default: undefined,
  },
  closeable: {
    type: [Boolean, String],
    default: undefined,
  },
  scrollableContent: {
    type: [Boolean, String],
    default: true,
  },
  /**
   * Whether the modal should represent a bottom sheet in mobile view.
   * If `true`, the modal will not be displayed as a bottom sheet in mobile view.
   *
   * @type {Boolean}
   * @default false
   */
  noBottomSheet: {
    type: [Boolean, String],
    default: false,
  },
  /**
   * Whether the modal should have no content padding.
   * If `true`, the modal will have no content padding.
   *
   * @type {Boolean}
   * @default false
   */
  noMobileContentPadding: {
    type: [Boolean, String],
    default: false,
  },
});

const persistent = ref(toBoolean(props.persistent));
watch(
  () => props.persistent,
  (value) => (persistent.value = toBoolean(value)),
);

const closeable = ref(toBoolean(props.closeable));
watch(
  () => props.closeable,
  (value) => (closeable.value = toBoolean(value)),
);

const showCloseButton = computed(() => closeable.value || (!persistent.value && props.closeable === undefined));

const open = ref(toBoolean(props.open));
watch(
  () => props.open,
  (value) => (open.value = toBoolean(value)),
);

watch(open, (value) => {
  emit("update-open", value);
  if (!value) {
    emit("close");
  }
});

const root = ref(null);
const { isGtSmScreen } = useBreakpoints(root);

const noBottomSheet = computed(() => toBoolean(props.noBottomSheet));
const showBottomSheet = computed(() => !noBottomSheet.value && !isGtSmScreen.value);
const contentComponent = computed(() => (showBottomSheet.value ? "sp-bottom-sheet" : "sp-dialog"));
const classModifiers = computed(() => ({
  "--bp-gt-sm": isGtSmScreen.value,
}));

function handleDialogClose() {
  open.value = false;
}

function handleDialogUpdateOpen({ detail }) {
  const [value] = detail;
  open.value = value;
}
</script>

<style>
:host {
  display: block;
  --modal-header-background-color: var(--sp-comp-modal-header-background-color, var(--sp-sys-color-primary));
  --modal-header-color: var(--sp-comp-modal-header-color, var(--sp-sys-color-on-primary));
  --modal-padding: var(--sp-comp-modal-padding, calc(var(--sp-ref-spacing-8) * 1.5));
}
</style>

<style scoped lang="scss">
.sp-modal {
  --card-height: 94vh;
  --card-title-min-height: var(--sp-comp-modal-header-min-height, calc(var(--sp-ref-spacing-8, 1rem) * 4));

  &.--bp-gt-sm {
    --card-height: 90vh;
  }
}
.sp-modal__card {
  --sp-comp-card-height: var(--card-height);
  --sp-comp-card-padding-inline: var(--modal-padding);
}

.sp-modal__card-title {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  background-color: var(--modal-header-background-color);
  color: var(--modal-header-color);
  padding: var(--sp-comp-modal-header-padding-block, var(--sp-ref-spacing-4, 0.5rem))
    var(--sp-comp-modal-header-padding-inline, var(--modal-padding));
  font-size: var(--sp-comp-modal-header-font-size, 1.125rem);
  font-weight: var(--sp-comp-modal-header-font-weight, var(--sp-sys-font-weight-bold));
  font-family: var(--sp-comp-modal-header-font-family, var(--sp-sys-font-family-bold));
  border-bottom: var(--sp-comp-modal-header-border-bottom, none);
  min-height: var(--card-title-min-height);
}

.close-button {
  --sp-ce-button-bg-color: var(--sp-comp-modal-close-button-bg-color, var(--modal-header-background-color));
  transform: translateX(var(--sp-comp-modal-close-button-icon-offset, var(--sp-ref-spacing-4, 0.5rem)));
}

.close-button::part(icon) {
  --fill: var(--sp-comp-modal-close-button-icon-fill, var(--modal-header-color));
}

.close-button:focus {
  --sp-ce-button-focus-opacity: 0;
}
.sp-modal-alert-container {
  position: sticky;
  top: var(--sp-comp-modal-alert-container-top, var(--modal-padding));

  width: 100%;
  z-index: var(--sp-comp-modal-alert-z-index, var(--sp-sys-z-index-max, 99));

  .sp-modal__alert {
    position: absolute;
  }
}
</style>
