<template>
  <slot ref="root" />
</template>

<script setup>
import { watchImmediate } from "@vueuse/core";
import { computed, ref, watch } from "vue";
import { useBreakpoints } from "../../composables/breakpoints";

defineProps({
  alignSelf: {
    type: String,
    default: undefined,
    validator: (value) => ["auto", "start", "end", "center", "baseline", "stretch"].includes(value),
  },
  alignItems: {
    type: String,
    default: "start",
    validator: (value) => ["start", "center", "end", "stretch"].includes(value),
  },
  cols: {
    type: [String, Number],
    default: 1,
  },
  flexDirection: {
    type: String,
    default: "column",
    validator: (value) => ["row", "row-reverse", "column", "column-reverse"].includes(value),
  },
  noStartGap: {
    type: Boolean,
    default: false,
  },
  noEndGap: {
    type: Boolean,
    default: false,
  },
  /*
   * The number of columns to span on small screens.
   *
   * @type {String|Number}
   */
  sm: {
    type: [String, Number],
    default: undefined,
  },
  offset: {
    type: [String, Number],
    default: undefined,
  },
  offsetSm: {
    type: [String, Number],
    default: undefined,
  },
});

const root = ref(null);
const host = computed(() => root.value?.getRootNode?.()?.host);

const { isSmScreen } = useBreakpoints();

/**
 * We watch the host element to become available, then we watch the isSmScreen breakpoint and stop watching the host element.
 *
 * This is because the host element is not available immediately, so we need to wait for it to be available before we
 * can watch the isSmScreen breakpoint, which will use the host element in order to apply attributes to it.
 */
const stopHostWatcher = watch(host, () => {
  stopHostWatcher();
  watchImmediate(isSmScreen, setisSmScreen);
});

function setisSmScreen() {
  host.value.classList.toggle("--is-sm-screen", isSmScreen.value);
}
</script>

<style lang="scss">
$total-cols: 12;
$flex-directions: row row-reverse column column-reverse;
$align-self-options: (
  auto: auto,
  start: flex-start,
  end: flex-end,
  center: center,
  baseline: baseline,
  stretch: stretch,
);
$align-items-options: (
  start: flex-start,
  center: center,
  end: flex-end,
  stretch: stretch,
);

:host {
  --total-cols: #{$total-cols};
  --cols: 1;
  --offset: 0;
  --width: calc(100% / var(--total-cols) * var(--cols));

  display: flex;
  width: 100%;
  flex-basis: 0;
  flex-grow: 1;
  flex-direction: column;
  max-width: 100%;
  padding-left: var(--column-gap-start);
  padding-right: var(--column-gap-end);
  margin-inline-start: calc(100% / var(--total-cols) * var(--offset));
  box-sizing: border-box;
}

:host(.--is-sm-screen[sm]),
:host([cols]) {
  flex: 0 0 var(--width);
  max-width: var(--width);
}

@for $i from 1 through $total-cols {
  :host([cols="#{$i}"]),
  :host(.--is-sm-screen[sm="#{$i}"]) {
    --cols: #{$i};
    --width: calc(100% / var(--total-cols) * var(--cols));
  }
  :host([offset="#{$i}"]),
  :host(.--is-sm-screen[offset-sm="#{$i}"]) {
    --offset: #{$i};
    margin-inline-start: calc(100% / var(--total-cols) * var(--offset));
  }
}

:host([no-start-gap]) {
  padding-left: 0;
}

:host([no-end-gap]) {
  padding-right: 0;
}

@each $direction in $flex-directions {
  :host([flex-direction="#{$direction}"]) {
    flex-direction: #{$direction};
  }
}

:host([flex-direction="row"]) {
  gap: var(--sp-comp-col-column-gap, 0.25ch);
}

@each $key, $value in $align-self-options {
  :host([align-self="#{$key}"]) {
    align-self: #{$value};
  }
}

@each $key, $value in $align-items-options {
  :host([align-items="#{$key}"]) {
    align-items: #{$value};
  }
}
</style>
